import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { WarningAmberOutlined, Google, PaletteOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Select,
  Space,
  Stack,
  Switch,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { updateAdminSettings, updateDisclaimers } from "src/redux/redux-store";
import useAdminSettings from "src/hooks/useAdminSettings";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { FeedbackComponent } from "src/components/common/Feedback";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "src/components/common/Buttons/FocusButton";

const OtherSettings: FunctionComponent = () => {
  const theme = useMantineTheme(); 
  const dispatch = useAppDispatch() 
  const { adminSettings, enableGDrive, loading, feedback, error, updateDisclaimer } =
    useAdminSettings();

  const [disclaimers, setDisclaimers] = useState(adminSettings?.disclaimers)
  const {backgroundColor, backgroundColorExterior} = useResponsive()

  useEffect(() => {
    setDisclaimers(adminSettings?.disclaimers)
  }, [adminSettings?.disclaimers])

  return (
    <StyledContainer theme={{backgroundColor: backgroundColorExterior}}>
      <StyledContainerInset theme={{backgroundColor: backgroundColor}}>
      <Container p={"lg"} fluid>
        <LoadingOverlay visible={loading} />
        <Text size={FONT_SIZES.h1} fw={600}>
          Other Settings
        </Text>
        <Text c="dimmed" size={14}>
          Update disclaimers and logo, put on maintenance notice and trigger
          google drive file import feature.
        </Text>

        <Card w={"60%"} bg={backgroundColor} withBorder my={"md"}>
          <TextInput
            styles={(theme) => ({
              input: {
                marginTop: theme.spacing.xs,
              },
            })}
            placeholder="XXXXX"
            onChange={(event) => setDisclaimers(event.currentTarget.value)}
            value={disclaimers}
            label="Disclaimers"
          />
      <Stack maw={"100%"} my={"md"}>
        <FocusButton
        iconSize="1.25rem"
        textSize={FONT_SIZES.h3}
        icon="save"
        text="Update Disclaimers"
        onClick={() => updateDisclaimer(disclaimers, () => {
          dispatch(updateDisclaimers(disclaimers));
        })}
        />
        <FocusButton 
        iconSize="1.25rem"
        textSize={FONT_SIZES.h3}
        icon="paletteOutlined"
        text="Change Logo"
        />
      </Stack>

          <Card withBorder>
          <Group position="apart">
          <Group>
            <WarningAmberOutlined fontSize={"small"} />
            <Text size={"sm"}>Maintainence Notice</Text>
          </Group>
          <Switch readOnly />
        </Group>

          <Group mt={"sm"} position="apart">
            <Group>
              <Google fontSize={"small"} />
              <Text size={"sm"}>File Import From Google Drive</Text>
            </Group>
            <Switch onChange={(e) => enableGDrive(e.currentTarget.checked)} checked={adminSettings.gdrive_enabled} />
          </Group>
            {/* <Group position="apart">
              <Group>
                <MailRounded />
                <Text>Maintainence Notice</Text>
              </Group>

              <Switch 
              readOnly
                  // onChange={(e) => enableGDrive(e.currentTarget.checked)}
                  // checked={adminSettings.}
              />
            </Group>

            <Group mt={"sm"} position="apart">
              <Group>
                <GoogleIcon />
                <Text>File Import From Google Drive</Text>
              </Group>

              <Switch
                onChange={(e) => enableGDrive(e.currentTarget.checked)}
                checked={adminSettings.gdrive_enabled}
              />
            </Group> */}
          </Card>
        </Card>
        {feedback ? (
          <FeedbackComponent
          error={error}
          feedback={feedback}
          />
        ) : null}
      </Container>
      </StyledContainerInset>
    </StyledContainer>
  );
};

export default OtherSettings;
