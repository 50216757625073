import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { store } from "../configureStore"
import { ChatMessage } from "src/interfaces/Chat"

interface chatStore {
  loading: boolean
  stopStream: boolean
  messages: ChatMessage[]
  tracelessMessages: ChatMessage[]
  traceless: boolean
  streaming: boolean
  suggestedQuestions: string[]
}

const initialState: chatStore = {
  loading: false,
  stopStream: false,
  messages: [],
  tracelessMessages: [],
  streaming: false,
  traceless: false,
  suggestedQuestions: []
}

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTraceless: (state, action: PayloadAction<boolean>) => {
      state.traceless = action.payload
    },
    setStreaming: (state, action: PayloadAction<boolean>) => {
      state.streaming = action.payload
    },
    setStopStream: (state, action: PayloadAction<boolean>) => {
      state.stopStream = action.payload
    },
    setMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = action.payload
    },
    streamChatMessage: (state, action: PayloadAction<string>) => {
      const chunk = action.payload;
      const lastMessageIndex = state.messages.length - 1;
      const lastMessage = state.messages[lastMessageIndex]

      // Append the chunk to the last message
      state.messages[lastMessageIndex].content = lastMessage.content.concat(chunk);
    },
    addMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages = [...state.messages, action.payload]
    },
    clearMessages: (state) => {
      state.messages = state.messages.slice(0, 1)
    },
    addTracelessMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.tracelessMessages = [...state.tracelessMessages, action.payload]
    },
    streamTracelessChatMessage: (state, action: PayloadAction<string>) => {
      const chunk = action.payload;
      const lastMessageIndex = 1;
      const lastMessage = state.tracelessMessages[lastMessageIndex]

      // Append the chunk to the last message
      state.tracelessMessages[lastMessageIndex].content = lastMessage.content.concat(chunk);
    },
    clearTracelessMessages: (state) => {
      state.tracelessMessages = []
    },
    setSuggestedQuestions: (state, action: PayloadAction<string[]>) => {
      state.suggestedQuestions = action.payload
    },
    clearSuggestedQuestions: (state) => {
      state.suggestedQuestions = []
    },
  },
})

export const { setLoading, setMessages, addMessage, addTracelessMessage, clearTracelessMessages, setStopStream, streamTracelessChatMessage, setTraceless, clearMessages, clearSuggestedQuestions, setSuggestedQuestions, streamChatMessage, setStreaming} = chat.actions


export const updateChatLoading = (loading: boolean) => ({
  type: setLoading.type,
  payload: loading,
})

export const stopStream = (stream: boolean) => ({
  type: setStopStream.type,
  payload: stream,
})

export const updateChatList = (chats: ChatMessage[]) => ({
  type: setMessages.type,
  payload: chats,
})

export const updateStreaming = (streaming: boolean) => ({
  type: setStreaming.type,
  payload: streaming,
})

export const streamMessage = (message: string) => ({
  type: streamChatMessage.type,
  payload: message,
})

export const appendMessageList = (chat: ChatMessage) => ({
  type: addMessage.type,
  payload: chat,
})

export const appendTracelessMessageList = (chat: ChatMessage) => ({
  type: addTracelessMessage.type,
  payload: chat,
})

export const updateSuggestedQuestionsList = (suggestedQuestions: string[]) => ({
  type: setSuggestedQuestions.type,
  payload: suggestedQuestions,
})

export default chat.reducer
