import {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TopNavbar from "src/components/TopNavbar";
import UpgradeCard from "src/components/UpgradeCard";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  FileButton,
  Grid,
  Group,
  Image,
  ScrollArea,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Tooltip,
  createStyles,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  ArrowLeft,
  ArrowRight,
  ArrowRightAltSharp,
  Check,
  CheckOutlined,
  DeleteOutline,
  Download,
  FastForward,
  FastRewind,
  FirstPage,
  Google,
  LastPage,
  LastPageRounded,
  LastPageTwoTone,
  MailRounded,
  PaletteOutlined,
  RefreshOutlined,
  Search,
  UploadFile,
} from "@mui/icons-material";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Documents } from "src/interfaces/Documents";
import { documentService } from "src/services/services/documents/documents.api";
import { ResponseKind } from "src/services/services/MainService";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { UserRole, role } from "src/enums/User";
import { confirmDeletionModal } from "./common/ConfirmationModal";
import Icon from "./common/Icons/Icons/Icon";
import { IconButton } from "./common/Buttons/IconButton";
import { FONT_SIZES } from "src/utils/styles";
import { sendFailureNotification, sendFeedbackNotification, sendNotification } from "src/utils/notifications";
import { NotificationTypes } from "src/enums/Notifications";
import { buttonStyles } from "./common/GlobalStyles";
import BaseTable from "./Tables/BaseTable";
import { ScrollableTable } from "./Tables/ScrollableTable";
import Config from "src/config/config";

const FILE_SIZE_LIMIT = Config.FILE_SIZE_LIMIT
const MAX_FILE_COUNT = Config.FREE_PLAN_FILE_LIMIT
const MAX_FILE_COUNT_PREMIUM = Config.PREMIUM_PLANS_FILE_UPLOAD_LIMIT

interface UploadDocs {
  files: Documents[];
  onPressDownload: (fileName: string) => void;
  onPressDelete?: () => void;
  setSelectedFiles: React.Dispatch<React.SetStateAction<Documents[]>>;
  onRefresh: () => void;
  deleteSingleFile: (fileName: string) => void;
  uploadDriveFile?: (link: string) => Promise<number>;
  selectedFiles: Documents[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFiles: File[];
  width?: string;
}

const useStyles = createStyles((theme) => ({
  navbar: {
    borderRadius: "0.8125rem",

    "@media (min-width: 992px)": {
      // Adjustments for devices wider than 768px
      marginTop: "1.25rem",
      marginLeft: "1.25rem",
    },

    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
    // Adjust the border as needed (width, style, color)
  },
  container: {
    borderRadius: 10,
  },
}));

const UploadDocs = (props: UploadDocs) => {
  const theme = useMantineTheme();
  const isPremiumUser = useAppSelector(state => state.user).paid
  const [listItem, setListItem] = useState(0);
  const userRole = useAppSelector((state) => state.user.role)
  const { classes } = useStyles();
  const [showDrive, setShowDrive] = useState(false);
  const [gDriveLink, setGDriveLink] = useState("");
  const [gDriveLinkError, setGDriveLinkError] = useState("");
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(false)
  const {
    files,
    onPressDownload,
    setSelectedFiles,
    onPressDelete = () => {},
    setUploadedFiles,
    uploadedFiles,
    selectedFiles,
    uploadDriveFile,
    onRefresh,
    width,
    deleteSingleFile,
  } = props;
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    resetRef.current?.();
  };

  useEffect(() => {
    if(uploadedFiles.length == 0){
      clearFile()
    }
  }, [uploadedFiles])


  const deleteFiles = () => {
      onPressDelete()
  }


  const checkFileLimit = () => {
    if(isPremiumUser){
      if(files?.length >= MAX_FILE_COUNT_PREMIUM){
        sendFeedbackNotification(NotificationTypes.fileLimit, `Premium account only supports ${MAX_FILE_COUNT_PREMIUM} at one time`)
        return true
      }
    }else{
      if(files.length >= MAX_FILE_COUNT){
        sendFeedbackNotification(NotificationTypes.fileLimit, `Free account only supports ${MAX_FILE_COUNT} at one time`)
        return true
      }
    }
    return false
  }

  const deleteOneFile = (name: string) => {
    confirmDeletionModal({title:"Deleting file", feedback: "You are about to delete this file", 
                 onClose: () => {},
                 onConfirm: () => deleteSingleFile(name),
  })
  }
 
  const uploadDrive = async () => {
    if (uploadDriveFile) {
      const response = await uploadDriveFile(gDriveLink);

      if (response == 0) {
        setGDriveLink("");
        setGDriveLinkError("");
        setShowDrive(false);
      } else if (response == 1) {
        setGDriveLinkError("There was some problem with your link");
      }
    }
  };

  return (
    <>
      <Center>
        <Button.Group w={"100%"} orientation="vertical">
          <>
        {userRole == UserRole.admin ? 
        <div className="py-4">
          <FileButton
          resetRef={resetRef}
          onChange={setUploadedFiles}
          accept="application/pdf, text/plain"
          multiple
        >
          {(props) => (
           <IconButton {...props} icon="upload" text="Upload PDF or TXT files" />
          )}
        </FileButton>
        </div> : 
        <div className="pt-4">
          <FileButton
          resetRef={resetRef}
          onChange={(file) => {
            if(file?.size){
              if(checkFileLimit()) return  
              if (file?.size < FILE_SIZE_LIMIT){
                setUploadedFiles((prev) => [file])
              }else{
                sendFailureNotification(NotificationTypes.fileSize, `File size exceeds 15mbs`)
              }
          }
          }}
          accept="application/pdf, text/plain"
        >
          {(props) => (
           <IconButton {...props} icon="upload" text="Upload PDF or TXT files" />
          )}
        </FileButton>
        </div>}
          </>
          {userRole == UserRole.admin && 
          <Button
            // disabled={!appSettings?.gdrive_enabled}
            loaderPosition="center"
            leftIcon={<GoogleIcon color="primary" fontSize={"1rem"} />}
            onClick={() => setShowDrive((prev) => !prev)}
            styles={buttonStyles}
          >
            <Text size={FONT_SIZES.text}>Import from Google Drive</Text>
          </Button>}
        </Button.Group>
      </Center>
          <ScrollableTable 
          onDownload={onPressDownload}
          data={files}
          onSingleDelete={deleteOneFile}
          onRefresh={onRefresh}
          onDeleteFiles={onPressDelete}

          selectedData={selectedFiles}
          setSelectedData={setSelectedFiles}

/>
      {showDrive ? (
        <>
          <Group my={"md"} position="center">
            <TextInput
              error={gDriveLinkError}
              value={gDriveLink}
              onChange={(e) => setGDriveLink(e.currentTarget.value)}
              w={"100%"}
              rightSection={
                <ActionIcon onClick={uploadDrive}>
                  <CheckOutlined/>
                </ActionIcon>
              }
              miw={"70%"}
              placeholder="Paste your Gdrive link here"
            />
          </Group>
        </>
      ) : null}

    </>
  );
};

export default UploadDocs;
