import { ButtonStylesNames, ButtonStylesParams, Styles } from "@mantine/core";
import { COLORS } from "src/utils/styles";

export const buttonStyles: Styles<ButtonStylesNames, ButtonStylesParams> = (theme) => ({
    root: theme.colorScheme == "light" ? {
        backgroundColor: COLORS.lightButton, 
        // border: `1px solid ${COLORS.border}`,
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.darken(COLORS.lightButton, 0.05),
          }),   
        width: '100%'  
    }: {
        backgroundColor: COLORS.darkButton,
        // border: `1px solid ${COLORS.border}`,
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.lighten(COLORS.darkButton, 0.5),
          }),
          width: '100%' 
    },
    leftIcon: theme.colorScheme == "light" ? {
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.darken(COLORS.lightButton, 0.5),
          }), 
    }: {
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.lighten(COLORS.darkButton, 0.5),
          }),
    },
    inner: {
        justifyContent: 'flex-start', // Align text and icons inside the button to the left
      },
}) 

export const butttonStylesNoIcon: Styles<ButtonStylesNames, ButtonStylesParams> = (theme) => ({
    root: theme.colorScheme == "light" ? {
        backgroundColor: COLORS.lightButton, 
        // border: `1px solid ${COLORS.border}`,
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.darken(COLORS.lightButton, 0.05),
          }),   
        width: '100%'  
    }: {
        backgroundColor: COLORS.darkButton,
        // border: `1px solid ${COLORS.border}`,
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.lighten(COLORS.darkButton, 0.5),
          }),
          width: '100%' 
    },
    leftIcon: theme.colorScheme == "light" ? {
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.darken(COLORS.lightButton, 0.5),
          }), 
    }: {
        '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.lighten(COLORS.darkButton, 0.5),
          }),
    },
  }) 