import { useEffect, useState } from "react"
import { updateAdminSettings } from "src/redux/redux-store"
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks"
import { ResponseKind } from "src/services/services/MainService"
import { adminService } from "src/services/services/admin/admin.api"
import { authService } from "src/services/services/auth/auth.api"

const useAdminSettings = () => {
    const [feedback, setFeedback] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const adminSettings = useAppSelector(state => state.app.adminConfig)
    const dispatch = useAppDispatch()  

    const getSettings = async () =>{
      const admin_config = await adminService.getConfig()
                    
      if(admin_config.kind == ResponseKind.OK){
        if(admin_config?.data){
          dispatch(updateAdminSettings(admin_config?.data))
        }
    }}

    const enableGDrive = async (value: boolean) => {
      try{
        setLoading(true)
        const response  = await adminService.updateConfig({
          gdrive_enabled: value
        })
        if(response.kind == ResponseKind.OK){
          setError(false)
          setFeedback("GDrive link was successfully updated");
          setTimeout(() => setFeedback(''), 3000)
        }else{
          throw "Error"
        }
      }catch(e){
        setError(true)
        setFeedback("GDrive link couldn't be updated");
        setTimeout(() => setFeedback(''), 3000)
      }finally{
        getSettings()
        setLoading(false)
      }
    }


    const enableMaintenanceNotice = async (value: boolean) => {
      try{
        setLoading(true)
        const response  = await adminService.updateConfig({
          gdrive_enabled: value
        })
        if(response.kind == ResponseKind.OK){
          setError(false)
          setFeedback("GDrive link was successfully updated");
          setTimeout(() => setFeedback(''), 3000)
        }else{
          throw "Error"
        }
      }catch(e){
        setError(true)
        setFeedback("GDrive link couldn't be updated");
        setTimeout(() => setFeedback(''), 3000)
      }finally{
        getSettings()
        setLoading(false)
      }
    }

    const updateDisclaimer = async (value: string, cb: () => void) => {
      try{
        setLoading(true)
        const response  = await adminService.updateConfig({
          disclaimers: value
        })
        if(response.kind == ResponseKind.OK){
          setError(false)
          setFeedback("Disclaimer was successfully updated");
          cb()
          setTimeout(() => setFeedback(''), 3000)
        }else{
          throw "Error"
        }
      }catch(e){
        setError(true)
        setFeedback("Disclaimer couldn't be updated");
        setTimeout(() => setFeedback(''), 3000)
      }finally{
        getSettings()
        setLoading(false)
      }
    }
  
    const updateGreetingsMessage = async (value: string) => {
      try{
        setLoading(true)
        const response  = await adminService.updateConfig({
          greeting_message: value
        })
        if(response.kind == ResponseKind.OK){
          setError(false)
          setFeedback("Greetings Message was successfully updated");
          setTimeout(() => setFeedback(''), 3000)
        }else{
          throw "Error"
        }
      }catch(e){
        setError(true)
        setFeedback("Greetings Message couldn't be updated");
        setTimeout(() => setFeedback(''), 3000)
      }finally{
        getSettings()
        setLoading(false)
      }
    }  

    const updateLLMConfig = async (values: any) => {
      try{
        setLoading(true)
        const response  = await adminService.updateConfig(values)
        if(response.kind == ResponseKind.OK){
          setError(false)
          setFeedback("LLM Config was successfully updated");
          setTimeout(() => setFeedback(''), 3000)
        }else{
          throw "Error"
        }
      }catch(e){
        setError(true)
        setFeedback("LLM Config couldn't be updated");
        setTimeout(() => setFeedback(''), 3000)
      }finally{
        getSettings()
        setLoading(false)
      }
    }  

    const updateRegionLLMPrompt = async (region: string, prompt: string) => {
      try {
        setLoading(true);
        const response = await adminService.updateConfig({
          region_llm_prompts: { [region]: prompt }
        });
        if (response.kind == ResponseKind.OK) {
          setError(false);
          setFeedback(`LLM Prompt for ${region} was successfully updated`);
          setTimeout(() => setFeedback(''), 3000);
        } else {
          throw "Error";
        }
      } catch (e) {
        setError(true);
        setFeedback(`LLM Prompt for ${region} couldn't be updated`);
        setTimeout(() => setFeedback(''), 3000);
      } finally {
        getSettings();
        setLoading(false);
      }
    };

    useEffect(() => {
      getSettings()
    }, [])

    return {
        feedback: feedback,
        getSettings: getSettings,
        updateGreetingsMessage,
        error: error,
        updateLLMConfig,
        loading: loading,
        updateDisclaimer: updateDisclaimer,
        enableGDrive: enableGDrive,
        adminSettings: adminSettings,
        updateRegionLLMPrompt
    }
}

export default useAdminSettings;
