// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { authRoutes } from '../routes/auth';
import { LoadingOverlay } from '@mantine/core';
import { UserRole } from 'src/enums/User';


const ProtectedRoute = ({ children }) => {
  const { token, loading, } = useAuth();

  if (loading) {
    // Optionally, render a loading indicator while checking the token
    return <LoadingOverlay visible={loading}/>;
  }

  window.addEventListener('logout', () => <Navigate to={authRoutes.LOGIN} replace />);

  if (!token?.access_token) {
    // If there's no token, redirect to the login page
    return <Navigate to={authRoutes.LOGIN} replace />;
  }

  return children;
};

export default ProtectedRoute;