import { useTheme } from "@emotion/react";
import { Avatar, AvatarStylesNames, AvatarStylesParams, Styles, Text, useMantineTheme } from "@mantine/core"
import useResponsive from "src/hooks/useResponsive";
import { User } from "src/interfaces";
import { COLORS, FONT_SIZES } from "src/utils/styles";

interface ProfileCardProps{
user: User
}

export const ProfileCard = (props: ProfileCardProps) => {
    const {user} = props
    const {isMobile} = useResponsive()
    const theme = useMantineTheme()

    const extractInitials = (userName: string) => {
        if (!userName) return ""; // Return empty if no name is provided
    
        // Split the name into parts and filter out any empty results from extra spaces
        const nameParts = userName.trim().split(/\s+/);
    
        // Check if we have at least two parts from which to construct initials
        if (nameParts.length > 1) {
          // Use the first letter of the first and last name parts
          return (
            nameParts[0][0] + nameParts[nameParts.length - 1][0]
          ).toUpperCase();
        } else {
          // If there's only one part, just return the first letter of this part
          return nameParts[0][0].toUpperCase();
        }
      };

    return(<div className="flex flex-row space-x-2 px-2 items-center">
        <Avatar
        bg= {theme.colorScheme == "dark" ? "dark" : COLORS.border}
        alt="Profile"
        styles={{root: {border: `1px solid ${COLORS.border}`,}}}
        radius= "xl"
        size={isMobile ? "1.75rem" : "2rem"}
      >
        {extractInitials(user?.name)}
      </Avatar>
      <Text weight={600} style={{fontSize: FONT_SIZES.text}}>{user?.email}</Text>
      
      </div>)
}
