export enum NotificationTypes{
    fileUpload = "File Upload",
    fileLimit = "Documents limit",
    downloadRestricted = 'Download Restricted',
    deleteRestricted = 'Delete Restricted',
    fileDeletion = "File Deletion",
    fileSize = "File Size"
}

export enum PubSubNotificationTypes{
    fileUpload = "file_upload",
    fileDeletion = "file_delete",
    appInfoUpdate = "app_info_updated"
}

