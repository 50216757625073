import { Modal, Button, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { COLORS } from 'src/utils/styles';

interface ErrorMessage {
    error: string
    handleClose: () => void
}

export function ErrorMessage(props: ErrorMessage) {
  const {error, handleClose} = props  
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if(error){
        setOpened(true)
    }else{
        setOpened(false)
    }
  }, [error])

  const onClose = () => {
    handleClose()
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        transitionProps={{transition: "slide-down", timingFunction: "ease", duration: 500}}
        title="Something went wrong"
        withOverlay ={false}
        closeOnClickOutside
        styles= {(theme) => ({body: {marginTop: 10, fontSize: 11,}, header: { height: 20}, title: {color: COLORS.error}, close: {}})}
      >
        <Text>{error}</Text>
      </Modal>
    </>
  );
}