export const COLORS = {
    bg: "#f5f5f5",
    primary: "#AA2C76",
    chat: "#ffefe0",
    error: "#ff3333",
    icon: "#BEBEBE",
    border: "#D0D0D0",
    lightButton: "#f5f5f5",
    // darkButton: "#080808"
    darkButton:"#28282B"
}

export const FONT_SIZES = {
    h1: 18,
    h2: 14,
    h3: 13,
    text: 11,
}