import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined, Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  Container,
  Grid,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { SocialAuthButton } from "src/components/common/Buttons/SocialAuthButton";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { clearToken, save, setRole, setToken } from "src/utils/storage";
import { UserRole, token } from "src/enums/User";
import { userRoutes } from "src/navigators/routes/user";
import { useAuth } from "src/hooks/useAuth";
import { adminRoutes } from "src/navigators/routes/AdminRoutes";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  updateAdminSettings,
  updateAppSettings,
  updateUser,
} from "src/redux/redux-store";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import Icon from "src/components/common/Icons/Icons/Icon";

const useStyles = createStyles((theme) => ({
  inputField: {
    // padding: rem(14), // Adjust padding as needed
    borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
  },
}));

const SPECIAL_CHARACTERS = ['-', '!', '@', '#', '$', '%', '=', ':', '?', '.', '/', '|', '~', '>'];

// Convert the special characters to a regex-safe string
const specialCharactersRegex = `[${SPECIAL_CHARACTERS.map(c => '\\' + c).join('')}]`;

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(new RegExp(specialCharactersRegex), "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
});

const ResetPassword: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const { login, checkRole } = useAuth();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState(false);
  const theme = useMantineTheme();
  const url = window?.location?.href;
  const user = useAppSelector((state) => state.user);
  const backgroundColor =
    theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.white;

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (url.includes("token")) {
      const urls = url.split("?");
      const breakTwo = urls[1].split("&");
      let token = breakTwo[0];
      let email = breakTwo[1];
      token = token.substring(token.indexOf("=") + 1);
      email = email.substring(email.indexOf("=") + 1);

      setEmail(email);
      setToken(token);
    }
  }, [url]);

  const goToUnwildered = useCallback(() => {
    window.open("https://www.unwildered.co.uk/");
  }, []);

  const resetPassword = useCallback(
    async (values: any) => {
      setLoading(true);

      if (!token && !email) {
        alert("Can't reset your password");
        return;
      }
      try {
        const response = await authService.resetPassword(
          token,
          email,
          values.password
        );

        if (response.kind == ResponseKind.OK) {
          setVerifyPassword(true);
        } else {
          throw "Error";
        }
      } catch (e) {
        alert("You can not update your password right now!");
      } finally {
        checkRole();
        setLoading(false);
      }
    },
    [email, token]
  );

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(schema),
  });

  return (
    <Center maw={"75vh"} h={"100vh"} mx="auto">
      <Stack spacing={"lg"} maw={"85%"}>
        <Center>
          <Image
            onClick={goToUnwildered}
            src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            fit="contain"
          />
        </Center>
        {verifyPassword ? (
          <div className="flex flex-col gap-8">
            <div className="flex flex-row justify-center items-center gap-2">
              <Icon size="1.5rem" color="green" name="success" />
              <Text size={FONT_SIZES.h2} className=" text-pretty">
                Your password was successfully changed
              </Text>
              
            </div>
            <Button
                variant="outline"
                onClick={() => navigation("/auth/login")}
              >
                Sign In
              </Button>
          </div>
        ) : (
          <>
            <Space h={10} />
            <Text size={FONT_SIZES.h2} className="text-pretty">
              Enter new password
            </Text>
            <form onSubmit={form.onSubmit((values) => resetPassword(values))}>
              <Stack spacing={"sm"}>
                <TextInput
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  rightSection={
                    <img
                      src="/view_password.png"
                      style={{
                        width: rem(16),
                        height: rem(12),
                        cursor: "pointer",
                      }}
                      onClick={togglePasswordVisibility}
                    />
                  }
                  {...form.getInputProps("password")}
                  className={classes.inputField}
                />
              </Stack>
              <Group position="center" mt="lg">
                <AppButton
                  loading={loading}
                  text="Confirm Password"
                  type="submit"
                />
              </Group>
            </form>
          </>
        )}
      </Stack>
    </Center>
  );
};

export default ResetPassword;
