import { ActionIcon, Group, Text } from "@mantine/core"
import { FacebookRounded } from "@mui/icons-material"
import { useCallback } from "react"

export const AppStatusCardFooter = () => {
    const goToFacebook = useCallback(() => {
        window.open("https://www.facebook.com/p/Unwildered-100079907851879/")
      }, [])

    return(
        <Group position="apart">
        <ActionIcon onClick={goToFacebook}><FacebookRounded style={{ color: "white" }} /></ActionIcon>
        <Text style={{ color: "white" }} size={"xs"}>
          © Unwildered. 2024
        </Text>
      </Group>
    )
}