import { FunctionComponent, useCallback, useEffect, useState } from "react";
import TopNavbar from "src/components/TopNavbar";
import UpgradeCard from "src/components/UpgradeCard";
import Sidebar from "src/components/SideBar";
import TopNavbarAdmin from "src/components/TopNavbarAdmin";
import {
  Button,
  Card,
  Container,
  Group,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { updateAdminSettings } from "src/redux/redux-store";
import useAdminSettings from "src/hooks/useAdminSettings";
import { FeedbackComponent } from "../common/Feedback";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "../common/Buttons/FocusButton";

const ConfigureLLMList = () => {
  const {adminSettings, updateLLMConfig, error, feedback} = useAdminSettings()
  const [temperature, setTemperature] = useState(adminSettings?.llm_temperature.toString())
  const [role, setRole] = useState(adminSettings?.llm_role)
  const [prompt, setPrompt] = useState(adminSettings?.llm_prompt)
  const theme = useMantineTheme()
  const {backgroundColor, backgroundColorExterior} = useResponsive()

  useEffect(() => {
    setRole(adminSettings?.llm_role)
    setPrompt(adminSettings?.llm_prompt)
    setTemperature(adminSettings?.llm_temperature.toString())
  }, [adminSettings])

  const submitValues = () => [
    updateLLMConfig({
      llm_prompt: prompt,
      llm_temperature: Number(temperature),
      llm_role: role
    })
  ]
  
  return (
      <Card w={"100%"} bg={backgroundColor} withBorder my={"md"}>
        <Text fw={500}>Parameters</Text>
        <Group my={"sm"} position="apart">
          <Select
            disabled
            label="LLM Selection"
            placeholder={adminSettings.llm_model_name}
            data={[
              { value: "react", label: "React" },
              { value: "ng", label: "Angular" },
              { value: "svelte", label: "Svelte" },
              { value: "vue", label: "Vue" },
            ]}
            value={adminSettings.llm_model_name}
          />
               <TextInput 
          inputMode= "numeric"
          onChange={(event) => setTemperature(event.currentTarget.value)}
          value={temperature}
          label="Temperature" />
        </Group>

        <TextInput
          my={"sm"}
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          onChange={(event) => setRole(event.currentTarget.value)}
          value={role}
          label="Role"
        />

        <TextInput
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          onChange={(event) => setPrompt(event.currentTarget.value)}
          value={prompt}
          label="Prompt"
        />
        <Space h={50} />
        <FocusButton 
        icon="save"
        text="Save"
        onClick={submitValues}
        />
        {feedback ? (
          <FeedbackComponent
          width="100%"
          error={error}
          feedback={feedback}
          />
        ) : null}
 
      </Card>
  );
};

export default ConfigureLLMList;
