import { ChatMode } from "src/enums/Chat"

interface Options {
    key: number
    label: string
    mode: ChatMode
    tooltip: string
}

export const optionButtons: Options[] = [
{key: 0, label: "Simplify", mode: ChatMode.Simplify, tooltip: "Make the response easier to understand"}, 
{key: 1, label: "Elaborate", mode: ChatMode.Elaborate, tooltip: "Explain with more detail"}, 
{key: 2, label: "Step by step", mode: ChatMode.Get_Legal_Precedent, tooltip: "Read what you could consider step-by-step"},]
