const Config = {
  API_URL: process.env.REACT_APP_API_URL,
  AUTH_URL: `${process.env.REACT_APP_API_URL}/auth`,
  USER_URL: `${process.env.REACT_APP_API_URL}/users`,
  CHAT_URL: `${process.env.REACT_APP_API_URL}/users/chat`,
  DOCUMENTS_URL: `${process.env.REACT_APP_API_URL}/users/documents`,
  ADMIN_URL: `${process.env.REACT_APP_API_URL}/admin`,
  PAYMENTS_URL: `${process.env.REACT_APP_API_URL}/payment`,
  INSTRUMETATION_KEY: process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY,
  FREE_PLAN_FILE_LIMIT: Number(process.env.REACT_APP_FREE_PLAN_FILE_UPLOAD_LIMIT),
  FREE_PLAN_MSG_LIMIT: Number(process.env.REACT_APP_FREE_PLAN_MSG_LIMIT),
  PREMIUM_PLANS_FILE_UPLOAD_LIMIT: Number(process.env.REACT_APP_PREMIUM_PLANS_FILE_UPLOAD_LIMIT),
  FILE_SIZE_LIMIT: Number(process.env.REACT_APP_FILE_SIZE_LIMIT),
  WEB_PUB_SUB_URL: process.env.REACT_APP_WEBPUBSUB_URL,
  AZURE_STORAGE_URL: process.env.REACT_APP_AZURE_STORAGE_URL
};

export default Config
