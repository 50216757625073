import { notifications } from "@mantine/notifications";
import { NotificationTypes } from "src/enums/Notifications";

interface Notification{
    title: string
    message: string
    type: "success" | "error" | "neutral"
}

function generateShortId() {
  const randomValue = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return randomValue.substring(0, 8); // Take only the first 8 characters
}

export const sendNotification = (props: Notification) => {
    const {type, message, title} = props
    
    const notificationColor = type == "neutral" ? "blue" : type == "error" ? "red" : "green"

    // Most used notification props
    notifications.show({
    id: generateShortId(),
    withCloseButton: true,
    autoClose: 3000,
    title: title,
    message: message,
    color: notificationColor,
    className: 'my-notification-class',
    loading: false,
  });
}

export const sendFailureNotification = (type: NotificationTypes, message: string) => {
  sendNotification({
    title: `${type} failed`,
    message: message,
    type: "error",
  });
}

export const sendSuccessNotification = (type: NotificationTypes, message: string) => {
  sendNotification({
    title: `${type} succeeded`,
    message: message,
    type: "success",
  });
}

export const sendFeedbackNotification = (type: NotificationTypes, message: string) => {
  sendNotification({
    title: `Restricted`,
    message: message,
    type: "neutral",
  });
}