import {
  ActionIcon,
  Button,
  Card,
  Container,
  Divider,
  Image,
  List,
  Space,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { FocusButton } from "src/components/common/Buttons/FocusButton";
import Icon from "src/components/common/Icons/Icons/Icon";
import useResponsive from "src/hooks/useResponsive";
import { PriceItem } from "src/interfaces/Payments";
import { userRoutes } from "src/navigators/routes/user";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { paymentService } from "src/services/services/payment/payment.api";
import { COLORS, FONT_SIZES } from "src/utils/styles";
// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function PricingPage() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const { isMobile } = useResponsive();
  const isMidSizeScreen = useMediaQuery("(max-width: 1200px)");
  const [prices, setPrices] = React.useState<PriceItem[] | null>(null);
  const [paymentUrl, setPaymentUrl] = React.useState('');

  const getPrices = async () => {
    const response = await paymentService.getPrices();
    if (response.kind == ResponseKind.OK) {
      if (response.data) setPrices(response.data?.prices.reverse());
    }
  };

  const createSession = async (priceId: string) => {
    try {
      const response = await paymentService.createPaymentSession(priceId);
      if (response.kind === ResponseKind.OK && response.data) {
        setPaymentUrl(response.data.url)
        return response.data.url
      } else {
        // Handle other kinds of responses or errors
        console.error("Failed to create payment session or no URL returned");
      }
    } catch (error) {
      // Handle errors from the payment service
      console.error("Error creating payment session:", error);
    }
  };

  const handleButtonClick = async (priceId: string) => {
    const url = await createSession(priceId);
    if (url) {
      window.location.href = url;
    }
  };

  const navigateBack = () => {
    navigate(userRoutes.DASHBOARD);
  };

  React.useEffect(() => {
    getPrices();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="relative h-20 xxs:h-24 flex items-center">
        <Text 
          fw={600}
          className="absolute left-1/2 text-lgi xxs:text-sm transform -translate-x-1/2"
        >
          Caira Pricing Plans
        </Text>
        <div className="flex-1"></div>{" "}
        {/* This empty div pushes the left content to align left */}
      </div>
      <Divider />
      <div className=" mt-8 overflow-y-auto">
        <div
          className={`${
            isMidSizeScreen ? "grid grid-rows-3" : "grid grid-cols-3"
          }`}
        >
          
          {prices?.map((item, index) => (
            <Card
              key={index}
              className="bg-white flex flex-col justify-center m-4 gap-2"
            >
              {/* <Image
                radius={4}
                height={isMobile ? "6rem" : "8rem"}
                width={isMobile ? "6rem" : "8rem"}
                src="/appIcon.jpeg"
              /> */}
              <Space h={"1rem"} />
              <Text fw={600} size={"2.5rem"} >
                {item.lookup_key}
              </Text>
              {/* <Text style={{color: "gray"}}>{item.product.name}</Text> */}
  

              <div className= "flex flex-row items-center gap-2">
              <Text fw={"bold"} size={"2.5rem"} >
              {"\u00A3"}{(item.unit_amount / 100)}
              </Text>
              
              <div className= "flex flex-col">
              <Text style={{color: "gray"}} size={".75rem"}>
                every
              </Text>
              <Text style={{color: "gray"}} size={".75rem"}>
               {item.recurring.interval_count == 1 ? "month" : item.recurring.interval_count + " months"}
              </Text>
              </div>
            
              </div>
              <Space h={".5rem"} />
              <Button
                radius={30}
                onClick={() => handleButtonClick(item.id)}
                h={"3rem"}
                bg={COLORS.primary}
                w={300}
              >
                <Text style={{color: "white"}} size={"lg"} >Subscribe</Text>
              </Button>
          
              <Space h={".5rem"} />
              <Text className="my-1" size={"sm"}>
                   This includes: 
                  </Text>
              <List
                px={"sm"}
                spacing="xs"
                size="md"
                center
              >
               {item.product.marketing_features.map((feature) => (<List.Item>
                  <Text size={"sm"}>
                   {feature.name}
                  </Text>
                </List.Item>))}
              </List>

            </Card>
          ))}
        </div>
      </div>

      <div className="fixed w-full flex items-center justify-center bottom-0 h-10">
        <Text size={"xs"}>Subscriptions are non refundable</Text>
      </div>
    </div>
  );
}

export default PricingPage;
