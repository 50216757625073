import { FunctionComponent, useCallback } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  FileButton,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  ScrollArea,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  DeleteOutline,
  Download,
  Google,
  MailRounded,
  PaletteOutlined,
  Search,
} from "@mui/icons-material";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { useDocuments } from "src/hooks/useDocuments";
import { useKnowledgebase } from "src/hooks/useKnowledgebase";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import UploadDocs from "src/components/UploadDocs";
import { ErrorMessage } from "src/components/common/ErrorMessage/ErrorMessage";
import UploadFilesDrawer from "src/components/UploadFilesDrawer";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { KnowledgebaseFilesDrawer } from "src/components/KnowledgebaseFilesDrawer";
import useResponsive from "src/hooks/useResponsive";

const ManageKnowledgeBase: FunctionComponent = () => {
  const {
    files,
    loading,
    deleteFiles,
    downloadFile,
    uploadDoc,
    uploadFiles,
    setSelectedFiles,
    getDocs,
    selectedFiles,
    error,
    dismissError,
    uploadQueue,
    setUploadedFiles,
    clearQueue,
    uploadDriveFile,
    deleteSingleFile,
  } = useKnowledgebase();
  const appSettings = useAppSelector((state) => state.app.appSettings);
  const theme = useMantineTheme();
  const {backgroundColor, backgroundColorExterior} = useResponsive()

  return (
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <StyledContainerInset theme={{backgroundColor: backgroundColor}}>
      <Container size={"md"} mx={0} p={"lg"}>
        <ErrorMessage error={error} handleClose={dismissError} />
        <LoadingOverlay visible={loading}/>
        <KnowledgebaseFilesDrawer onPressClose={clearQueue} files={uploadQueue} />
        <Text size={FONT_SIZES.h1} fw={600}>
          Manage Knowledge Base
        </Text>
        <Text c="dimmed" size={14}>
          Modify assistant’s core knowledge base.
        </Text>

        <UploadDocs
          uploadedFiles={uploadFiles}
          setSelectedFiles={setSelectedFiles}
          setUploadedFiles={setUploadedFiles}
          deleteSingleFile={deleteSingleFile}
          onRefresh={getDocs}
          selectedFiles={selectedFiles}
          uploadDriveFile={uploadDriveFile}
          onPressDelete={deleteFiles}
          onPressDownload={downloadFile}
          files={files}
        />
      </Container>
      </StyledContainerInset>
    </StyledContainer>
  );
};

export default ManageKnowledgeBase;
