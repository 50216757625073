import { Button, ButtonProps, rem } from "@mantine/core";
import { StringSchema } from "yup";

interface AppButtonProps extends ButtonProps {
  preset?: "default" | "inverted";
  text?: string;
}

export const AppButton = (props: AppButtonProps) => {
  const { preset, text, ...rest } = props;


  
  return (
    <Button styles={(theme) => ({
            root: {
              borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
              color: theme.colorScheme == "dark" ? theme.white : theme.black
            },
    })} variant="outline" h={rem(40)}  w={"100%"}  {...rest}>
     {text}
    </Button>
  );
};
