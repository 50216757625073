import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import Config from './config/config';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: Config.INSTRUMETATION_KEY,
        extensions: [reactPlugin],
        namePrefix: "Frontend React",
        extensionConfig: {
            [reactPlugin.identifier]: { history: null }
        }
    }
});

appInsights.loadAppInsights();

// Add telemetry initializer to include a unique prefix
appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'Frontend'; // You can change 'Frontend' to any unique identifier you prefer
});

export { reactPlugin, appInsights };
