import { TrashIcon } from "@heroicons/react/24/solid";
import {
  Drawer,
  Progress,
  Text,
  Stack,
  useMantineTheme,
  Group,
  Loader,
  CheckIcon,
  Divider,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Cancel, CancelOutlined, CancelPresentation, CancelRounded, CancelTwoTone, DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import { useNotifications } from "src/hooks/useNotifications";
import { Documents } from "src/interfaces/Documents";
import { COLORS } from "src/utils/styles";

const { setLogLevel } = require("@azure/logger");

setLogLevel("verbose");

interface UploadFilesDrawer {
  files: Documents[];
  visible?: boolean;
  onPressClose?: () => void;
}

interface FileIcon{
  status: string | undefined
}

const FileIcon = (props: FileIcon) => {
  const {status} = props
  return( <div className="flex-1 max-w-[10%] overflow-hidden flex items-center justify-center">
  {status === "Completed" ? (
    <div
      className="bg-green-500 flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <CheckIcon className="w-3 h-3 text-white fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : status === "Deleted" ? (
    <div
      className=" bg-red-600 flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <TrashIcon className="w-3 h-3 text-white fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : status === "Upload Failed"  ? (
    <div
      className=" flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <CancelRounded className="w-6 h-6 text-red-600 fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : (
    <Loader size={20} />
  )
  }
</div>)
}

export function KnowledgebaseFilesDrawer(props: UploadFilesDrawer) {
  const { files, onPressClose } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const client = useNotifications()?.client;
  const isMatchces = useMediaQuery("(max-width: 992px)");
  const [list, setList] = useState(files);
  const [initialized, setInitialized] = useState(false);
  const [processedCount, setProcessedCount] = useState(0)
  const useTheme = useMantineTheme();

  const closeList = useCallback(() => {
    // setList((prev) => []);
    close();
  }, [close]);

  const wipeList = useCallback(() => {
    close()
    setList((prev) => [])

  }, [])

  const listenForQueueFiles = useCallback(async () => {
    if (!initialized) {
      client?.on("group-message", (data) => {
        if (data.message.data) {
          console.log("Notification receieved", data.message.data);
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.fileUpload) {
            setList((prevFiles) => {
              // Update the status of the relevant file
              const newFiles = prevFiles.map((file, index) => {
                if (file.filename === message.file_name) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if(message.status == "Completed" || message.status == "Upload Failed"){
                setProcessedCount((prev) => prev + 1)
              }

              // Check if all files are completed
              const allCompleted = newFiles.every(
                (file) =>
                  file.status === "Completed" || file.status === "Upload Failed"
              );

              // If all files are completed, filter them out
              if (allCompleted) {
                if (onPressClose) {
                  console.log("Here is the call");
                  onPressClose();
                  setTimeout(wipeList, 2000)
                }
              }

              // Otherwise, return the updated list
              return newFiles;
            });
          }
          if (message.event_type === PubSubNotificationTypes.fileDeletion) {
            setList((prevFiles) => {
              // Update the status of the relevant file
              const newFiles = prevFiles.map((file) => {
                if (file.filename === message.file_name) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if(message.status == "Deleted"){
                setProcessedCount((prev) => prev + 1)
              }

              // Check if all files are completed
              const allCompleted = newFiles.every(
                (file) => file.status === "Deleted"
              );

              // If all files are completed, filter them out
              if (allCompleted) {
                if (onPressClose) {
                  console.log("Here is the call");
                  onPressClose();
                  setTimeout(wipeList, 2000)
                }
              }

              // Otherwise, return the updated list
              return newFiles;
            });
          }
        }
      });
      try{
        await client?.joinGroup("admin")
      }catch(e){
        console.log("Error when joining", e); 
      }
    }
  }, [setList, client, initialized, onPressClose]);

  useEffect(() => {
    if (files.length > 0) {
      setList(files);
    }
  }, [files]);

  useEffect(() => {
    if (list?.length > 0) {
      open();
      listenForQueueFiles();
      setInitialized(true);
    }
  }, [list, client]);

  const calculateProgress = useCallback((status: string | undefined) => {
    switch (status) {
      case "Uploading":
        return 15;
      case "Uploaded":
        return 50;
      case "Chunking and Vectorising in progress":
        return 70;
      case "Completed":
        return 100;
      case "Queued For Google Drive Transfer":
        return 25;
      case "Transferring From Google Drive":
        return 40;
      case "Deleting":
        return 40;
      case "Deleted":
        return 100;
      default:
        return 100;
    }
  }, []);

  return (
    <>
      <Drawer
        opened={opened}
        transitionProps={{
          transition: "rotate-left",
          duration: 150,
          timingFunction: "linear",
        }}
        withCloseButton = {false}
        onClose={closeList}
        title={"Process Knowledgebase Files"}
        padding="lg"
        position="bottom"
        withOverlay={false}
        styles={(theme) => ({
          inner: {
            maxWidth: isMatchces ? "100%" : 350,
          },
          body: {
            marginTop: 10,
          },
          title: {
            fontStyle: "italic",
          },
          header: {
            backgroundColor: theme.colors.blue[0],
            borderRadius: "16px 16px 0 0",
            position: "sticky",
            height: 10,
          },
        })}
      >
        {/* {list.map((file, index) => (
          <Stack w={"100%"} style={{ marginBottom: 20 }} key={index}>
            <div className="overflow-hidden">
              <Text>{file.filename}</Text>
            </div>
            <Text align="right" style={{ color: file.status == "Completed" ? useTheme.colors.green[5] : useTheme.colors.blue[6] }}>
              {file.status}
            </Text>
          <Progress color={file.status == "Completed" ? "green" : "blue"} value={calculateProgress(file.status)}  animate = {file.status == "Completed" ? false : true} />
          </Stack>
        ))} */}
        {list.map((file, index) => (
          <>
            <div key={index} className="flex w-full items-center py-2">
              {" "}
              {/* Adds a border as divider */}
              <div className="flex-1 max-w-[90%] overflow-hidden">
                <Text style={{ fontSize: 14 }}>{file.filename}</Text>
              </div>
              <FileIcon status={file?.status} />
            </div>
            <Divider w={"100%"} />
          </>
        ))}
      </Drawer>
    </>
  );
}

export default UploadFilesDrawer;
