import { useCallback, useEffect, useRef, useState } from "react";
import { ChatMode } from "src/enums/Chat";
import { ChatMessage } from "src/interfaces/Chat";
import {
  appendMessageList,
  appendTracelessMessageList,
  clearTracelessMessages,
  setStopStream,
  stopStream,
  streamChatMessage,
  streamTracelessChatMessage,
  updateChatList,
  updateChatLoading,
  updateStreaming,
  updateSuggestedQuestionsList,
} from "src/redux/redux-store";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { chatService } from "src/services/services/chat/chat.api";

export const useChat = () => {
  const dispatch = useAppDispatch();
  const traceless = useAppSelector((state) => state.chat.traceless);
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const chatLoading = useAppSelector((state) => state.chat.loading);
  const streaming = useAppSelector((state) => state.chat.streaming);
  const messages = useAppSelector((state) => state.chat).messages;
  const tracelessMessages = useAppSelector(
    (state) => state.chat
  ).tracelessMessages;
  const stopStreamCheck = useAppSelector((state) => state.chat).stopStream;
  const [inputMessage, setInputMessage] = useState("");
  const stopStreamCheckRef = useRef(stopStreamCheck);

  // Update ref whenever the stopStreamCheck changes
  useEffect(() => {
    stopStreamCheckRef.current = stopStreamCheck;
  }, [stopStreamCheck]);

  const getSuggestedQuestions = async () => {
    const response = await chatService.getSuggestedQuestions();

    if (response.kind == ResponseKind.OK) {
      const messages = response?.data;

      if (messages) {
        dispatch(updateSuggestedQuestionsList(messages));
      }
    }
  };

  const sendMessage = useCallback(async () => {
    sendMessageToBackend(inputMessage, ChatMode.NA);
    // const trimmedMessage = inputMessage.trim();
    // if (!trimmedMessage || trimmedMessage === '\n') {
    //   setInputMessage("");
    //   return;
    // }
    //   dispatch(
    //     appendMessageList({
    //       role: "User",
    //       content: inputMessage,
    //     })
    //   );
    //   sendMessageToBackend(inputMessage, ChatMode.NA);
    //   setInputMessage("");
  }, [inputMessage]);

  const sendMessageToBackend = async (message: string, chatMode: ChatMode) => {
    try {
      dispatch(updateStreaming(true));

      dispatch(clearTracelessMessages());
      const trimmedMessage = message.trim();

      if (chatMode === ChatMode.NA) {
        setInputMessage("");
        if (!trimmedMessage || trimmedMessage === "\n") {
          return;
        }

        if (traceless) {
          dispatch(
            appendTracelessMessageList({
              role: "User",
              content: message,
            })
          );
        } else {
          dispatch(
            appendMessageList({
              role: "User",
              content: message,
            })
          );
        }
      }

      const response = await chatService.sendMessage(
        message,
        traceless,
        chatMode
      );

      if (response) {
        if (traceless) {
          dispatch(
            appendTracelessMessageList({
              role: "AI",
              content: "",
            })
          );
        } else {
          dispatch(
            appendMessageList({
              role: "AI",
              content: "",
            })
          );
        }
        let printstring = "";
        let chunk = "";
        let decoder = new TextDecoder();
        const throttleRate = 100; // Throttle tokens to print per 100 milliseconds

        while (true) {
          if (stopStreamCheckRef.current) break;
          const { done, value } = await response.read();
          if (done) break;
          chunk = decoder.decode(value, { stream: true });

          // Throttle the tokens display (commented because of Azure OpenAI)
          await new Promise((resolve) => setTimeout(resolve, throttleRate));

          if (traceless) {
            dispatch(streamTracelessChatMessage(chunk));
          } else {
            dispatch(streamChatMessage(chunk));
          }

          chunk = "";
        }
        dispatch(stopStream(false));
      }
    } catch (e) {
    } finally {
      dispatch(updateStreaming(false));
      // getSuggestedQuestions()
    }
  };

  const getMessages = async (greeting_message: string) => {
    try {
      console.log("Called get messages ");

      dispatch(updateChatLoading(true));
      const response = await chatService.getAllMessages();

      if (response.kind == ResponseKind.OK) {
        const messages = response?.data?.messages;

        const tempMessages: ChatMessage[] = [
          { content: greeting_message, role: "AI", created_at: "" },
        ];

        if (messages != undefined) {
          dispatch(updateChatList([...tempMessages, ...messages]));
        }
        // getSuggestedQuestions()
      }
    } catch (e) {
    } finally {
      dispatch(updateChatLoading(false));
    }
  };

  return {
    getMessages,
    sendMessage,
    sendMessageToBackend,
    chatLoading,
    messages,
    tracelessMessages,
    streaming,
    inputMessage,
    setInputMessage,
  };
};
