import {  UserRole, role, token } from "src/enums/User";
import { UserToken } from "src/interfaces";

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export async function loadString(key: string): Promise<string | null> {
  try {
    return localStorage.getItem(key);
  } catch {
    // not sure why this would fail... even reading the RN docs I'm unclear
    return null;
  }
}

export async function setToken(value: UserToken): Promise<boolean> {
  try {
    localStorage.setItem(token, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

export async function clearToken(): Promise<boolean> {
  try {
    localStorage.removeItem(token);
    return true;
  } catch {
    return false;
  }
}


/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export async function getToken(): Promise<UserToken | null> {
  try {
    let access_token = localStorage.getItem(token);
    let parsedToken: UserToken;

    if (typeof access_token == "string") {
      parsedToken = JSON.parse(access_token);
    } else {
      throw "error";
    }

    return parsedToken;
  } catch {
    return null;
  }
}


export async function setRole(value: UserRole): Promise<boolean> {
  try {
    localStorage.setItem(role, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

export async function clearRole(): Promise<boolean> {
  try {
    localStorage.removeItem(role);
    return true;
  } catch {
    return false;
  }
}
/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export async function getRole(): Promise<UserRole | null> {
  try {
    let user_role = localStorage.getItem(role);
    let parsedRole: UserRole;

    if (user_role && typeof Number(user_role) == "number") {
      return Number(user_role)
    } else {
      throw "error";
    }
  } catch {
    return null;
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function saveString(key: string, value: string): Promise<boolean> {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string): Promise<any | null> {
  try {
    const almostThere: any = localStorage.getItem(key);
    return JSON.parse(almostThere);
  } catch {
    return null;
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function save(key: string, value: any): Promise<boolean> {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export async function remove(key: string): Promise<void> {
  try {
    localStorage.removeItem(key);
  } catch {}
}

/**
 * Burn it all to the ground.
 */
export async function clear(): Promise<void> {
  try {
    localStorage.clear();
  } catch {}
}
