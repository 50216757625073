import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined, Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  Container,
  Grid,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { SocialAuthButton } from "src/components/common/Buttons/SocialAuthButton";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { clearToken, save, setRole, setToken } from "src/utils/storage";
import { UserRole, token } from "src/enums/User";
import { userRoutes } from "src/navigators/routes/user";
import { useAuth } from "src/hooks/useAuth";
import { adminRoutes } from "src/navigators/routes/AdminRoutes";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
    resetUser,
  updateAdminSettings,
  updateAppSettings,
  updateUser,
} from "src/redux/redux-store";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import Icon from "src/components/common/Icons/Icons/Icon";

const useStyles = createStyles((theme) => ({
  inputField: {
    // padding: rem(14), // Adjust padding as needed
    borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
  },
}));

const schema = Yup.object().shape({
    password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must have at least one special character"
    )
    .matches(/\d/, "Password must have at least one number"),
});

const DeleteAccount: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const { login, checkRole, logout } = useAuth();

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState(false)
  const theme = useMantineTheme()
  const url = window?.location?.href  
  const [verificationMessage, setVerificationMessage] = useState('');
  const user = useAppSelector((state) => state.user)
  const backgroundColor = theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.white

  useEffect(() => {
    if(url.includes("token")){
        const urls = url.split("?");
        const breakTwo = urls[1].split('&')
        let token = breakTwo[0]
        let email = breakTwo[1]
        token = token.substring(token.indexOf('=') + 1);
        email = email.substring(email.indexOf('=') + 1)
        deleteAccount(token, email)
    }
  }, [url])

  const goToUnwildered = useCallback(() => {
    window.open("https://www.unwildered.co.uk/");
  }, []);

  const deleteAccount = useCallback(async (tokenString: string, emailString: string) => {
    setLoading(true);

    if(!tokenString && !emailString){
        alert("Can't delete your account");
        return 
    }
    try {
      const response = await authService.deleteAccount(tokenString, emailString);
      if(response.kind == ResponseKind.OK){
        setVerificationMessage("Account deleted successfully")
        clearToken()
        dispatch(resetUser())
      }else{
        throw "Error"
      }
    } catch (e) {
      alert("You can not delete your account!");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Center maw={"75vh"} h={"100vh"} mx="auto">
      <Stack spacing={"lg"} maw={"85%"}>
        <Center>
          <div className="flex flex-col gap-4 justify-center items-center">
          <Image
            onClick={goToUnwildered}
            src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            fit="contain"
          />
             <Text size={"lg"}>
            {verificationMessage}
            </Text>
          </div>
        </Center>
      </Stack>
    </Center>
  );
};

export default DeleteAccount;
