import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { Button, Card, Container, Group, Select, Space, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { updateAdminSettings } from "src/redux/redux-store";
import useAdminSettings from "src/hooks/useAdminSettings";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { FeedbackComponent } from "src/components/common/Feedback";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "src/components/common/Buttons/FocusButton";

const ChatSettings: FunctionComponent = () => {
  const {adminSettings, updateGreetingsMessage, feedback, error} = useAdminSettings()
  const [greetingsMessage, setGreetingsMessage] = useState(adminSettings?.greeting_message)
  const theme = useMantineTheme()
  const {backgroundColor, backgroundColorExterior} = useResponsive()
  
  useEffect(() => {
    setGreetingsMessage(adminSettings.greeting_message)
  }, [adminSettings.greeting_message])

  return (
    <StyledContainer theme={{backgroundColor: backgroundColorExterior}}>
      <StyledContainerInset theme={{backgroundColor: backgroundColor}}>
    <Container p={"lg"} fluid>
      <Text size={FONT_SIZES.h1} fw={600}>
        Chat settings
      </Text>
      <Text c="dimmed" size={14}>
      Customise how the assistant greets user and modify initially suggested questions.
      </Text>

      <Card w={"60%"} bg={backgroundColor} withBorder my={"md"}>
        <TextInput
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          onChange={(event) => setGreetingsMessage(event.currentTarget.value)}
          value={greetingsMessage}
          label="Greetings Message"
        />

        <TextInput
          mt={"md"}
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          placeholder="You are a friendly assistant."
          label="Initial Suggested Questions"
        />
        <Space h={100} />
        <FocusButton text="Save" icon="save" iconSize="1.25rem" textSize={FONT_SIZES.h3} onClick={() => updateGreetingsMessage(greetingsMessage)}/>
      
      </Card>
      {feedback ? (
          <FeedbackComponent
          error={error}
          feedback={feedback}
          />
        ) : null}
    </Container>
    </StyledContainerInset>
    </StyledContainer>
  );
};

export default ChatSettings;
