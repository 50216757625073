import Config from "../../../config/config"
import { api, Api } from "../MainService/api"
import { ErrorKind } from "../MainService/apiProblem"
import { UserBase, UserToken } from "src/interfaces"
import { DownloadDoc, FileList, UploadFile } from "../documents/documents.api.types"
import { AdminAppSettings } from "src/interfaces/AppSettings"
import { DocumentContainerType } from "src/enums/Documents"

const ADMIN_URL = Config.ADMIN_URL
const DOCUMENTS_URL = `${ADMIN_URL}/knowledge-base`
const AZURE_URL = "https://cairav2devstorage.blob.core.windows.net";
export class AdminApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  
  }

  async updateConfig(request: any) {
    try {
      const response = await this.api.apisauce.put(`${ADMIN_URL}/config`, request)
  
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getConfig(){
    try {
      const response = await this.api.apisauce.get<AdminAppSettings>(
        `${ADMIN_URL}/config`,
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

  async uploadKnowledgebaseDoc(files: File[]) {
    try {
      const formData = new FormData()

      files.forEach(file => {
        formData.append("files", file)
      })

      const response = await this.api.apisauce.post<UploadFile>(`${DOCUMENTS_URL}/upload`, formData)

      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async uploadKnowledgebaseGdrive(gDriveLink: string) {
    try {
      const response = await this.api.apisauce.post<UploadFile>(`${DOCUMENTS_URL}/upload-gdrive?gdrivelink=${gDriveLink}`)
  
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getUserTokenKnowledgebase() {
    try {
      const response = await this.api.apisauce.get<{
        azure_storage_token: string;
      }>(`${DOCUMENTS_URL}/get-azure-storage-token`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async uploadDoc(
    files: File[],
    email: string,
    azure_token: string,
    container?: DocumentContainerType
  ) {
    try {
    let failedFiles: File[] = [];
    if (!container) {
      container = DocumentContainerType.document;
    }

    files.forEach(async (file, index) => {
      const config = {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type,
        },
      };
        const response = await this.api.apisauce.put(
          `${AZURE_URL}/${container}/${file.name}?${azure_token}&timeout=60`,
          file,
          config
        ); 

        if (!response.ok) {
          console.log("this file was not uploaded");
          failedFiles.push(file);
        }
    
    });
    return failedFiles;

  } catch (e) {
    console.log("File uploading failed");
    Promise.reject("Bad api call");
  }}

  async downloadKnowledgebaseDoc(id: string) {
    try {
      const response = await this.api.apisauce.get<DownloadDoc>(`${DOCUMENTS_URL}/download/${id}`)
  
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async deleteKnowledgebaseDocs(files: string[]) {
    try {
      const response = await this.api.apisauce.post(`${DOCUMENTS_URL}/delete-multiple`, {
        file_names: files
      })
  
      let data = await this.api.getResponse(response)

      return data
    } catch (e) {

      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data"}
    }
  }

  async getKnowledgebaseDocuments(){
    try {
      const response = await this.api.apisauce.get<FileList>(
        `${DOCUMENTS_URL}/list`,
      )
      let data = await this.api.getResponse(response, true)
      return data
    } catch (e) {
      console.log(e)
      return { kind: ErrorKind.BAD_DATA_ERROR }
    }
  }

}

export const adminService = new AdminApi(api)
