// GlobalState or Event Listener in a React component
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authRoutes } from '../routes/auth';

export function LogoutListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      // Perform logout operations
      navigate(authRoutes.LOGIN, {replace: true});
    };

    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('logout', handleLogout);
    };
  }, [navigate]);

  return null;
}