export enum FileType {
  csv = "csv",
  pdf = "pdf",
  txt = "txt",
  docx = "docx",
}

export enum DocumentContainerType {
  document = "container-consumer",
  knowledgebase = "container-knowledgebase"
}