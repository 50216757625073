import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Space,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import useAdminSettings from "src/hooks/useAdminSettings";
import { FeedbackComponent } from "../common/Feedback";
import { FocusButton } from "../common/Buttons/FocusButton";
import useResponsive from "src/hooks/useResponsive";

const ChatSettingsList: FunctionComponent = () => {
  const { adminSettings, updateGreetingsMessage, feedback, error } =
    useAdminSettings();
  const [greetingsMessage, setGreetingsMessage] = useState(
    adminSettings?.greeting_message
  );
  const theme = useMantineTheme();
  const {backgroundColor} = useResponsive()

  useEffect(() => {
    setGreetingsMessage(adminSettings.greeting_message);
  }, [adminSettings.greeting_message]);

  return (
    <Card w={"100%"} bg={backgroundColor} withBorder my={"md"}>
      <TextInput
        styles={(theme) => ({
          input: {
            marginTop: theme.spacing.xs,
          },
        })}
        onChange={(event) => setGreetingsMessage(event.currentTarget.value)}
        value={greetingsMessage}
        label="Greetings Message"
      />

      <TextInput
        mt={"md"}
        styles={(theme) => ({
          input: {
            marginTop: theme.spacing.xs,
          },
        })}
        placeholder="You are a friendly assistant."
        label="Initial Suggested Questions"
      />
      <Space h={100} />
        <FocusButton 
        text="Save"
        onClick={() => updateGreetingsMessage(greetingsMessage)}
        icon="save"
        />

      {feedback ? (
        <FeedbackComponent width="100%" error={error} feedback={feedback} />
      ) : null}
    </Card>
  );
};

export default ChatSettingsList;
