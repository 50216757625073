import { FunctionComponent, useCallback } from "react";
import TopNavbar from "src/components/TopNavbar";
import UpgradeCard from "src/components/UpgradeCard";
import Sidebar from "src/components/SideBar";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  FileButton,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  DeleteOutline,
  Download,
  Google,
  MailRounded,
  PaletteOutlined,
  Search,
} from "@mui/icons-material";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import useAdminSettings from "src/hooks/useAdminSettings";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { useKnowledgebase } from "src/hooks/useKnowledgebase";
import UploadDocs from "../UploadDocs";


const ManageKnowledgeBaseList: FunctionComponent = () => {
  const {
    files,
    loading,
    deleteFiles,
    downloadFile,
    uploadDoc,
    uploadFiles,
    setSelectedFiles,
    
    getDocs,
    selectedFiles,
    setUploadedFiles,
    uploadDriveFile,
    deleteSingleFile
  } = useKnowledgebase();
  const appSettings = useAppSelector((state) => state.app.appSettings);
  const theme = useMantineTheme()
  const backgroundColor = theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.white


  return (
    <Card w={"100%"} bg={backgroundColor} withBorder my={"md"}>
      <LoadingOverlay visible={loading} />
    <UploadDocs
        uploadedFiles={uploadFiles}
        setSelectedFiles={setSelectedFiles}
        setUploadedFiles={setUploadedFiles}
        deleteSingleFile={deleteSingleFile}
        onRefresh={getDocs}
        selectedFiles={selectedFiles}
        uploadDriveFile={uploadDriveFile}
        onPressDelete={deleteFiles}
        onPressDownload={downloadFile}
        files={files}
    />
      </Card>
  );
};

export default ManageKnowledgeBaseList;
