import {
  ActionIcon,
  Avatar,
  Button,
  Divider,
  Modal,
  ModalStylesNames,
  Space,
  Stack,
  Styles,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ArrowLeftRounded, SettingsRounded } from "@mui/icons-material";
import { IconButton } from "./common/Buttons/IconButton";
import { User } from "src/interfaces";
import { COLORS } from "src/utils/styles";
import { ProfileCard } from "./ProfileCard";
import useResponsive from "src/hooks/useResponsive";
import Icon from "./common/Icons/Icons/Icon";
import { useAppSelector } from "src/redux/redux-store/hooks";

interface UserSettingProps {
  logOut: () => void;
  switchToDarkTheme: () => void;
  userName: User;
  clearChat: () => void;
  deleteAccount?: () => void
}

export const UserSettingsModal = (props: UserSettingProps) => {
  const { logOut, userName, switchToDarkTheme, clearChat, deleteAccount } = props;
  const theme = useMantineTheme();
  const isPremiumUser = useAppSelector(state => state.user).paid
  const [modalOpened, { open, close }] = useDisclosure(false);

  return (
    <div>
      <ActionIcon onClick={open}>
        <Icon name="settings" size="1.5rem" />
      </ActionIcon>
      <Modal
        radius={10}
        styles={userSettingsModalStyles}
        transitionProps={{
          transition: "slide-down",
          timingFunction: "ease",
          duration: 500,
        }}
        opened={modalOpened}
        onClose={close}
        title={<ProfileCard user={userName} />}
      >
        <Divider />
        <Space h={10} />
        <Stack px={"sm"} spacing="md">          
          <IconButton
            onClick={switchToDarkTheme}
            icon={theme.colorScheme == "dark" ? "lightMode" : "darkMode"}
            text={
              theme.colorScheme == "dark"
                ? "Switch to Light Mode"
                : "Switch To Dark Mode"
            }
          />
        <IconButton disabled={!isPremiumUser} onClick={clearChat} icon="clear" text={"Clear Chat"} />
        {deleteAccount && <IconButton color={theme.colors.red[6]} onClick={deleteAccount} icon="delete" text={"Delete Account"} />}
        </Stack>
        <Space h={10} />
        <Divider />
        <Space h={10} />

        <div className="px-3">
          <IconButton onClick={logOut} icon="logout" text="Logout" />
        </div>
      </Modal>
    </div>
  );
};

const userSettingsModalStyles: Styles<ModalStylesNames> = (theme) => ({

  header: {
    backgroundColor:
      theme.colorScheme == "dark" ? 'black' : COLORS.bg,
  },
  content: {
    backgroundColor:
      theme.colorScheme == "dark" ? 'black' : COLORS.bg,
  },
});
