import { useMantineTheme } from "@mantine/core";

const UserMessage = (props: { message: string, traceless?: boolean }) => {
    const { traceless } = props
    const theme = useMantineTheme()
    return (
      <div className="self-stretch flex flex-col items-end justify-start pt-0 pb-6 pr-[0.009999999776482582px] pl-0 mq800:max-w-full">
           <div className={`rounded-tl-lg rounded-tr-none rounded-b-lg ${traceless ? "bg-theme-color-1": theme.colorScheme == "dark" ? "bg-slate-200" : "bg-color-theme-for-msg-dialogue"} flex flex-col items-start justify-start py-2.5 px-0`}>
          <div className="overflow-hidden flex flex-col items-start justify-start py-0 pr-3.5 pl-[15px]">
            <div className={`relative text-sm leading-[22px] font-chat-dante-ai-com-1470x705-default-poppins-regular-16 text-left ${theme.colorScheme == "dark" ? "text-black" : "text-white"} inline-block max-w-[269px]`}>
              {props.message}
            </div>
          </div>
        </div>
      </div>
    );
  };

export default UserMessage;