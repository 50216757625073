import { Card, Center, Group, Text, rem, useMantineTheme } from "@mantine/core"
import { CheckBoxOutlined, ErrorOutline } from "@mui/icons-material"
import { colors } from "@mui/material"

interface FeedbackProps{
    error: boolean
    feedback: string
    width?: string 
}
export const FeedbackComponent = (props: FeedbackProps) => {
    const theme = useMantineTheme()
    const {error, feedback, width} = props
    return (  <Card
        bg={!error ? theme.colors.green[4] : theme.colors.red[7]}
        withBorder
        mt={10}
        p={5}
        w={width || "30%"}
      >
        <Center>
        <Group spacing={"md"}>
           {error ? <ErrorOutline style={{color: theme.white}}/> : <CheckBoxOutlined style={{color: theme.white}}/>}
          <Text style={{color: "white", fontSize: rem(11)}}>{feedback}</Text>
          </Group>
        </Center>
       
      </Card>)
}