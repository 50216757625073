import { GeneralApiProblem } from "./apiProblem"

export enum ErrorKind {
  TIMEOUT = "timeout",
  CANNOT_CONNECT = "cannot-connect",
  SERVER = "server",
  UNKNOWN = "unknown",
  AUTHORIZATION_ERROR = "unauthorized",
  FORBIDDEN_ERROR = "forbidden",
  NOT_FOUND_ERROR = "not-found",
  CONFLICT_ERROR = "conflict",
  REJECTION_ERROR = "rejected",
  BAD_DATA_ERROR = "bad-data",
  CONNECTION_ERROR = "CONNECTION_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  CLIENT_ERROR = "CLIENT_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR",
}

export enum ResponseKind {
  OK = "ok",
}


export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

interface BackendResponse<T>{
  result?: T
  errors?: string[]
  errorDetails?: null
  status?: number
}

export interface Response<T = any> {
  errorDetails: {
    apiErrorCode?: string
    apiErrorSubCode?: string
  }
  errors?: string[]
  result: T
  status: number
}

type SuccessResponse<T> = {kind: ResponseKind.OK, data: BackendResponse<T>["result"]}

export type ResponseType<T> = SuccessResponse<T> | GeneralApiProblem