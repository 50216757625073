import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined, Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  Container,
  Grid,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  createStyles,
  useMantineTheme,
  ThemeIcon,
} from "@mantine/core";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { SocialAuthButton } from "src/components/common/Buttons/SocialAuthButton";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { clearToken, save, setRole, setToken } from "src/utils/storage";
import { UserRole, token } from "src/enums/User";
import { userRoutes } from "src/navigators/routes/user";
import { useAuth } from "src/hooks/useAuth";
import { adminRoutes } from "src/navigators/routes/AdminRoutes";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  updateAdminSettings,
  updateAppSettings,
  updateUser,
} from "src/redux/redux-store";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import Icon from "src/components/common/Icons/Icons/Icon";

const useStyles = createStyles((theme) => ({
  inputField: {
    // padding: rem(14), // Adjust padding as needed
    borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
  },
}));

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
});

const ForgotPassword: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const { login, checkRole } = useAuth();
  const dispatch = useAppDispatch();
  const [emailVerified, setEmailVerified] = useState(false)
  const [loading, setLoading] = useState(false);

  const goToUnwildered = useCallback(() => {
    window.open("https://www.unwildered.co.uk/");
  }, []);

  const forgotPassword = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.forgotPassword(values.email);

      if (response.kind == ResponseKind.OK) {
        setEmailVerified(true)
      } else {
        throw "Error";
      }
    } catch (e) {
      alert("Incorrect Email");
    } finally {
      setLoading(false);
    }
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(schema),
  });

  return (
    <Center maw={"75vh"} h={"100vh"} mx="auto">
      <Stack spacing={"lg"} maw={"85%"}>
        <Center>
          <Image
            onClick={goToUnwildered}
            src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            fit="contain"
          />
        </Center>
        <Space h={10} />
        {!emailVerified ? <>
        <Text size={FONT_SIZES.h2} className=" text-pretty" >We will send a verification link to this email if it matches an existing Caira account</Text>
        <form onSubmit={form.onSubmit((values) => forgotPassword(values))}>
          <Stack spacing={"sm"}>
            <TextInput
              placeholder="johndoe@gmail.com"
              rightSection={
                <img
                  src="/mail_box.png"
                  style={{ width: rem(14), height: rem(12) }}
                />
              }
              {...form.getInputProps("email")}
              className={classes.inputField}
            />
          </Stack>
          <Group position="center" mt="lg">
            <AppButton loading={loading} text="Send link" type="submit" />
          </Group>
        </form>

        <Space h={10} />

        <Center className="flex flex-col justify-center gap-1">
          <Text align="center">Don't have an account?</Text>
          <Text component="a" href="/auth/signUp" underline>
            Sign Up
          </Text>
        </Center>
        </> : <div className=" flex flex-row justify-center items-center gap-2" >
        <Icon size="1.5rem" color="green" name="success" />
        <Text size={FONT_SIZES.h2} className=" text-pretty" >We have sent a verification link to your email</Text>
      
        </div>}

      </Stack>
    </Center>
  );
};

export default ForgotPassword;
