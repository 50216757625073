import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./screens/auth/Login";
import Signup from "./screens/auth/Signup";
import VerifyEmail from "./screens/auth/VerifyEmail";
import Dashboard from "./screens/user/Dashboard";
import AdminRoutes from "./navigators/routes/AdminRoutes"; // Make sure this path is correct
import ProtectedRoute from "./navigators/wrapper/ProtectedRoute";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/redux-store/configureStore";
import { AuthProvider } from "./hooks/useAuth";
import { userRoutes } from "./navigators/routes/user";
import { authRoutes } from "./navigators/routes/auth";
import {
  AppShell,
  ButtonStylesParams,
  ColorScheme,
  ColorSchemeProvider,
  GlobalStyles,
  MantineProvider,
  Notification,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import Sidebar from "./components/SideBar";
import TopNavbarAdmin from "./components/TopNavbarAdmin";
import TopNavbar from "./components/TopNavbar";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import theme from "@material-tailwind/react/theme";
import { LogoutListener } from "./navigators/wrapper/LogoutListener"; // Adjust the import path as needed
import { Notifications } from "@mantine/notifications";
import { NotificationsProvider } from "./hooks/useNotifications";
import { ModalsProvider } from "@mantine/modals";
import { PersistGate } from "redux-persist/integration/react";
import PricingPage from "./screens/payment/PricingTables";
import PaymentFeedback from "./screens/payment/Feedback";
import ForgotPassword from "./screens/auth/ForgotPassword";
import ResetPassword from "./screens/auth/ResetPassword";
import { FONT_SIZES } from "./utils/styles";
import DeleteAccount from "./screens/auth/DeleteAccount";

function App() {
  const theme = useMantineTheme();
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const matches = useMediaQuery("(max-width: 992px)");
  const [opened, setOpened] = useState(false);

  const handleOpen = () => {
    setOpened((prev) => !prev);
  };


  function useShowHeader() {
    const location = useLocation(); // from react-router-dom
    return !location.pathname.startsWith("/auth") && !location.pathname.includes('/payment');
  }

  // Inside your AppShell component
  const showHeader = useShowHeader();
  const user = location.pathname.startsWith("/admin") ? "admin" : "user";
  // Your existing setup
  return (
    // Existing Provider and MantineProvider setup
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme: colorScheme,
              components: {
                Text: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      color:
                        theme.colorScheme == "dark" ? theme.white : theme.black,
                    },
                  }),
                },
                TextInput: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      backgroundColor:
                        theme.colorScheme == "dark" ? theme.black : theme.white,
                      
                    },
                  }),
                },
              },
            }}
          >
          <ModalsProvider >
          <NotificationsProvider url="">
            <AppShell
              padding={0}
              navbarOffsetBreakpoint={992}
              layout={matches ? "default" : "alt"}
              navbar={showHeader ? <Sidebar opened={opened} /> : <></>}
              header=
              {
                showHeader ? (
                  user == "admin" ? (
                    <TopNavbarAdmin opened={opened} handleOpen={handleOpen} />
                  ) : (
                  <TopNavbar opened={opened} handleOpen={handleOpen} />
                  )
                ) : (
                  <></>
                )
              }
            >
            
              <LogoutListener />
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to={userRoutes.DASHBOARD} />}
                  />
                  <Route path={authRoutes.LOGIN} element={<Login />} />
                  <Route path={authRoutes.SIGNUP} element={<Signup />} />
                  <Route path={authRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
                  <Route path={authRoutes.RESET_PASSWORD} element={<ResetPassword />} />
                  <Route path={authRoutes.DELETE_ACCOUNT} element={<DeleteAccount />} />
                  <Route      
                    path={authRoutes.VERIFY_ACCOUNT}
                    element={<VerifyEmail />}
                  />
                  {/* <Route path={authRoutes.FORGOT_PASSWORD}/> */}
                  {/* <Route path={authRoutes.RESET_PASSWORD} element={<VerifyEmail />} /> */}
                  <Route
                    path={userRoutes.DASHBOARD}
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                   <Route
                    path={userRoutes.PAYMENT_FEEDBACK}
                    element={
                      <ProtectedRoute>            
                        <PaymentFeedback />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={userRoutes.PAYMENT}
                    element={
                      <ProtectedRoute>            
                        <PricingPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/*"
                    element={
                      <ProtectedRoute>
                        <AdminRoutes />
                      </ProtectedRoute>
                    }
                  />
                  {/* Add other routes as needed */}
                </Routes>
            </AppShell>
            </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </AuthProvider>
      </PersistGate>
    </Provider>

    // Closing tags for Provider and MantineProvider
  );
}

export default App;
