import { Button, ButtonProps, ButtonStylesNames, ButtonStylesParams, Styles, Text, useMantineTheme } from "@mantine/core";
import { IconName } from "../Icons/Icons/iconRegistry";
import Icon from "../Icons/Icons/Icon";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { useColorScheme } from "@mantine/hooks";
import { buttonStyles } from "../GlobalStyles";

interface IconButton {
  icon: IconName;
  text: string;
  disabled?: boolean;
  color?: string
  loading?: boolean,
  onClick?: () => void,
}

export const IconButton = (props: IconButton) => {
  const { text, icon, onClick, disabled, color } = props;
  const theme = useMantineTheme()

  return (
      <Button disabled={disabled} onClick={onClick} styles={buttonStyles} leftIcon={<Icon size="1rem" color={color ? color : theme.colorScheme == "dark" ? "white" : "black"} name={icon} />}>
        <Text style={{fontSize: FONT_SIZES.text, color: color ? color : theme.colorScheme == "dark" ? "white" : "black"}}>{text}</Text>
      </Button>
  );
};

