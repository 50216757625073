// components/Icon.tsx
import React from 'react';
import { iconRegistry, IconName } from './iconRegistry';

interface IconProps {
  name: IconName;
  color?: string;
  size?: string;
}

const Icon: React.FC<IconProps> = ({ name, color = 'inherit', size = '24px' }) => {
  const IconComponent = iconRegistry[name];

  return <IconComponent style={{ color: color, fontSize: size }} />;
};

export default Icon;
