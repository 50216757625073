import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { store } from "../configureStore";
import { AdminAppSettings, AppSettings } from "src/interfaces/AppSettings";
import { getState } from "../types";
import { Dispatch } from "react";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";

export interface appStore {
  appSettings: AppSettings;
  adminConfig: AdminAppSettings;
}

const initialState: appStore = {
  appSettings: {
    greeting_message: "",
    disclaimers: "",
    gdrive_enabled: false,
    logo_link: "/unwildered-logo-horizontalremovebgpreview-1@2x.png",
  },
  adminConfig: {
    llm_model_name: "",
    llm_prompt: "",
    llm_role: "",
    llm_temperature: 0,
    logo_link: "/unwildered-logo-horizontalremovebgpreview-1@2x.png",
    greeting_message: "",
    gdrive_enabled: false,
    updated_at: "",
    disclaimers: "",
  },
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppSettings: (state, action: PayloadAction<AppSettings>) => {
      state.appSettings = action.payload;
    },
    setGreetingsMessage: (state, action: PayloadAction<string>) => {
      state.appSettings.greeting_message = action.payload;
    },
    setDislacimer: (state, action: PayloadAction<string>) => {
      state.appSettings.disclaimers = action.payload;
    },
    setAdminSettings: (state, action: PayloadAction<AdminAppSettings>) => {
      state.adminConfig = action.payload;
    },
  },
});

const { setAppSettings, setAdminSettings, setDislacimer } = app.actions;

export const updateAppSettings = (appSettings: AppSettings) => ({
  type: setAppSettings.type,
  payload: appSettings,
});

export const handleUpdateAppSettings = (
  cb?: (response: AppSettings) => void
) => {
  return async (dispatch: Dispatch<any>, state: getState) => {
    try {
      const response = await authService.getAppInfo();

      if (response.kind == ResponseKind.OK && response.data) {
        dispatch(updateAppSettings(response.data));
        if (cb) {
          cb(response.data);
        }
      } else {
        throw "Error";
      }
    } catch {
      console.log("Could not update user to the redux");
    }
  };
};

export const updateAdminSettings = (appSettings: AdminAppSettings) => ({
  type: setAdminSettings.type,
  payload: appSettings,
});

export const updateDisclaimers = (disclaimer: string) => ({
  type: setDislacimer.type,
  payload: disclaimer,
});

export default app.reducer;
