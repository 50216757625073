import { useCallback, useEffect, useState } from "react";
import { DocumentContainerType } from "src/enums/Documents";
import { Documents } from "src/interfaces/Documents";
import { updateAzureToken, updateUser, handleUserUpdate } from "src/redux/redux-store";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { adminService } from "src/services/services/admin/admin.api";
import { authService } from "src/services/services/auth/auth.api";
import { documentService } from "src/services/services/documents/documents.api";
import { useAuth } from "./useAuth";
import { NotificationTypes, PubSubNotificationTypes } from "src/enums/Notifications";
import { sendFailureNotification, sendNotification } from "src/utils/notifications";
import { useNotifications } from "./useNotifications";

export const useKnowledgebase = () => {
    const dispatch = useAppDispatch();
    const container = DocumentContainerType.knowledgebase
    const azure_token = useAppSelector((state) => state.user.azureStorageToken);
    const email = useAppSelector((state) => state.user.email);
    const { token } = useAuth();
  
    const [loading, setLoading] = useState(false);
  
    const [deleting, setDeleting] = useState(false);
  
    const [uploadFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadQueue, setUploadQueue] = useState<Documents[]>([]);
  
    const [files, setFiles] = useState<Documents[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<Documents[]>([]);
    const [failFiles, setFailFiles] = useState<Documents[]>([]);
  
    const [error, setError] = useState("");

    useEffect(() => {
      if (token) {
        getAzureToken();
        getDocs();
      }
    }, [token]);
  
    useEffect(() => {
      if (!email) {
        dispatch(handleUserUpdate());
      }
      if (uploadFiles?.length > 0 && azure_token && email) {
        uploadDoc(azure_token);
      }
    }, [uploadFiles, azure_token, email]);
  
    useEffect(() => {
      if(failFiles?.length > 0){
        for(let i = failFiles.length - 1; i>=0; i--){
          let item = failFiles[i]
          sendFailureNotification(NotificationTypes.fileUpload, `${item.filename} was not uploaded`)
        }
      }
    }, [failFiles])
  
    

    const transformFilesToQueueFiles = (files: File[]) => {
      const temp: Documents[] = files?.map((item, index) => {
        return {
          filename: item.name,
          content_type: item.type,
          status: "Uploading",
          id: index,
          error: "",
        };
      });
  
      return temp;
    };

    const getAzureToken = useCallback(async () => {
      const response = await adminService.getUserTokenKnowledgebase();
  
      if (response.kind == ResponseKind.OK && response.data) {
        dispatch(updateAzureToken(response.data?.azure_storage_token));
      }
    }, []);

    const clearQueue = useCallback(() => {
      setUploadQueue((prev) => [])
      getDocs()
    }, [])
  
    const dismissError = useCallback(() => {
      setError("");
    }, []);

  const uploadDoc = async (azure_token: string) => {
    const validationData = [...uploadFiles];
    const fileNames = validationData.map((item) => item.name);
    let filesToUpload: File[] = [];

    try {
      const validate_file_names = await documentService.validateFiles(
        fileNames)

      if (uploadFiles?.length > 200) {
        setError("You can upload max 200 files at one time");

        return;
      }
  
      if (validate_file_names.kind == ResponseKind.OK) {
        if (validate_file_names.data) {
          validate_file_names.data.files.forEach((item) => {
            if (item.exists) {
              sendFailureNotification(NotificationTypes.fileUpload, `File ${item.filename} already exists`)
              return;
            } else {
              let fileIndex = uploadFiles.findIndex(
                (i) => i.name == item.filename);
              filesToUpload.push(uploadFiles[fileIndex]);
            }
          });
          
          const queue = transformFilesToQueueFiles(filesToUpload);
          setUploadQueue((prev) => [...prev, ...queue]);
          // processQueue(queue);

          if(filesToUpload?.length > 0){
              const failed = await adminService.uploadDoc(
              filesToUpload,
              email,
              azure_token,
              container
            );
            console.log("Files that didn't upload", failed);
            if(failed && failed?.length > 0){
              // setUploadQueue([]);

              setError("A network issue was encounterd! Some files were not uploaded")
            }}
        
        }
      } else {
        throw "File names could not be validates";
      }
    } catch (error) {
      setError(`${error}`);
    } finally {
      getDocs();
      setUploadedFiles([]);
    }
  };
  //   try {
  //     console.log("called process queue");
  //     if (queue_files.length > 0) {
  //       const response = await adminService.getKnowledgebaseDocuments();
  //       if (response.kind === ResponseKind.OK && response.data) {
  //         let files = response.data.uploaded_files;
  //         let failedFiles = response.data.failed_files;

  //         // Create a new array from queue_files to avoid mutation
  //         let newQueue = [...queue_files];

  //         if (files && failedFiles) {
  //           for (let i = newQueue.length - 1; i >= 0; i--) {
  //             const file = newQueue[i];
  //             const present = files.findIndex(
  //               (q) => q.filename === file.filename
  //             );
  //             const failed = failedFiles.findIndex(
  //               (q) => q.filename === file.filename
  //             );
  //             if (failed !== -1) {
  //               // Remove failed file
  //               newQueue.splice(i, 1);
  //               sendNotification({
  //                 title: `${NotificationTypes.fileUpload} failed`,
  //                 message: `File ${file.filename} could not be uploaded`,
  //                 type: "error",
  //               });
  //             } else if (present !== -1) {
  //               // Update status
  //               newQueue[i].status = files[present].status;

  //               if (files[present].status === "Completed") {
  //                 // Remove completed file
  //                 newQueue.splice(i, 1);
  //                 sendNotification({
  //                   title: `${NotificationTypes.fileUpload} completed`,
  //                   message: `File ${file.filename} uploaded`,
  //                   type: "success",
  //                 });
  //                 getDocs();
  //               }
  //             }
  //           }
  //           setUploadQueue(newQueue);
  //           setTimeout(() => processQueue(newQueue), 10000);
  //         } else {
  //           setUploadQueue([]);
  //         }
  //       }
  //     } else {
  //       setUploadQueue([]);
  //     }
  //   } catch (error) {
  //     console.error("Error processing upload queue:", error);
  //   }
  // };

    const uploadDriveFile = async (link: string): Promise<number> => {
      setLoading(true);
      const response = await adminService.uploadKnowledgebaseGdrive(link);
  
      try {
        if (response.kind == ResponseKind.OK) {
          if (response && response?.data) {
            if (
              response.data.queued_files &&
              response.data.queued_files?.length > 0
            ) {
              setUploadQueue(response.data.queued_files);
              // processQueue(response.data.queued_files);
            }
            if (
              response.data?.failed_files?.length > 0 &&
              response.data?.queued_files?.length == 0
            ) {
              setFailFiles(response.data.failed_files);
  
              throw "Error";
            }
          }
        } else {
          throw "Error";
        }
      } catch (e) {
        return 1;
      } finally {
        setLoading(false);
        getDocs();
      }
      return 0;
    };

    const processDeletion = (fileNames: string[]) => {
      const updatedFiles = files.filter(
        (file) => !fileNames.includes(file.filename)
      );
      setFiles(updatedFiles);
  
    }
  
  
  const getDocs = async () => {
    try {
      setLoading(true);
      const response = await adminService.getKnowledgebaseDocuments();
      if (response.kind == ResponseKind.OK) {
        if (response?.data) {
          console.log("Files from doc api", response?.data);

          const queueFiles = response?.data?.processing_files ?? []
          const failedFiles = response?.data?.failed_files

          setFailFiles(failedFiles)
          setUploadQueue(queueFiles)
          setFiles(response.data.uploaded_files);
        }
      } else {
        throw "Error";
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  
    const downloadFile = async (name: string) => {
      sendFailureNotification(NotificationTypes.downloadRestricted, "The download feature is disabled on knowledgebase documents")
      // const response = await adminService.downloadKnowledgebaseDoc(name);
      // if (response.kind == ResponseKind.OK) {
      //   if (response.data) {
      //     fetch(response?.data?.download_link).then((response) => {
      //       response.blob().then((blob) => {
      //         // Creating new object of PDF file
      //         const fileURL = window.URL.createObjectURL(blob);
  
      //         // Setting various property values
      //         let alink = document.createElement("a");
      //         alink.href = fileURL;
      //         alink.download = name || "fileName";
      //         alink.click();
      //       });
      //     });
      //   }
      // }
    };

    const deleteFiles = async () => {
      if (selectedFiles?.length > 0) {
        const fileNamesToDelete = selectedFiles.map((item)=> item.filename);
        const deletedFiles = files.reduce((acc: Documents[], file) => {
          if (fileNamesToDelete.includes(file.filename)) {
            // Push a new object with updated status to the accumulator
            acc.push({ ...file, status: 'Deleting' });
          }
          return acc;
        }, []);
        setUploadQueue(deletedFiles)
        const response = await adminService.deleteKnowledgebaseDocs(Object.keys(selectedFiles));
  
        if (response.kind == ResponseKind.OK) {
          
          processDeletion(fileNamesToDelete)
        } else {
          // Optionally handle API errors
          console.error("Failed to delete files:");
        }
      }
    };
  
  


    const deleteSingleFile = async (fileName: string) => {
      if(fileName){
        const response = await adminService.deleteKnowledgebaseDocs([fileName]);
        if (response.kind == ResponseKind.OK) {
          getDocs()
        }
      }
    };
  
  
  
    return {
      deleteFiles,
      downloadFile,
      error,
      uploadDriveFile,
      uploadDoc,
      loading,
      failFiles,
      uploadQueue,
      clearQueue,
      getDocs,
      setSelectedFiles,
      selectedFiles,
      dismissError,
      deleteSingleFile,
      files,
      uploadFiles,
      setUploadedFiles,
    } 


}
