import Config from "../../../config/config"
import { api, Api } from "../MainService/api";
import { ErrorKind } from "../MainService/apiProblem";
import { ChatResponse, MessageList } from "./chat.api.types";
import { clearRole, clearToken, getToken, setToken } from "src/utils/storage";
import { UserToken } from "src/interfaces";
import { authService } from "../auth/auth.api";
import { ResponseKind } from "../MainService";

const CHAT_URL = Config.CHAT_URL;

export class ChatApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAllMessages() {
    try {
      const response = await this.api.apisauce.get<MessageList>(
        `${CHAT_URL}/get-msgs`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async sendMessage(user_msg: string, traceless: boolean, mode: number) {
    try {
      let parsedToken;
      const token = await getToken();

      if (token) {
        const todaysDate = new Date();
        const tokenExpiry = new Date(token?.expires_at);

        if (!(tokenExpiry.getTime() > todaysDate.getTime())) {
          const refresh = await authService.getRefreshToken(token.refresh_token);
          if (refresh.kind == ResponseKind.OK && refresh?.data) {
            const refresh_token = refresh.data;
            setToken(refresh_token)
            parsedToken = refresh_token?.access_token;
          }else{
            const event = new Event('logout');
            window.dispatchEvent(event);
          }
        } else {
          parsedToken = token.access_token;
        }
      }

      if (!parsedToken) {
        throw new Error("No access token available");
      }

      const response: any = await fetch(`${CHAT_URL}/send-msg`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${parsedToken}`,
        },
        body: JSON.stringify({
          user_msg: user_msg,
          traceless: traceless,
          mode: mode,
        }),
      });

      const reader = response.body.getReader();

      return reader;
    } catch (e) {
      // alert("Your link is bad")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async getSuggestedQuestions() {
    try {
      const response = await this.api.apisauce.get<string[]>(
        `${CHAT_URL}/suggested-qs`
      );

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async clearChat() {
    try {
      const response = await this.api.apisauce.delete(`${CHAT_URL}/clear-chat`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }
}

export const chatService = new ChatApi(api);
