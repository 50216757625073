import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import appReducer from './application/appReducer';
import chatReducer from "./chat/chatReducer";
import userReducer from "./user/userReducer";

const rootReducer = combineReducers({
  app: appReducer,
  chat: chatReducer,
  user: userReducer
});

// Configuration for redux-persist
const persistConfig = {
  key: 'root', // Key used for the storage
  storage,    // Define which storage to use (default is localStorage)
  whitelist: ['user'] // Optional: configure which reducers should be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Configure middleware to allow redux-persist actions to be dispatched
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
