import { TrashIcon } from "@heroicons/react/24/solid";
import {
  Drawer,
  Progress,
  Text,
  Stack,
  useMantineTheme,
  Group,
  Loader,
  CheckIcon,
  Divider,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Cancel, CancelOutlined, CancelPresentation, CancelRounded, CancelTwoTone, Delete, DeleteOutline } from "@mui/icons-material";
import { CircularProgress, colors } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import { useNotifications } from "src/hooks/useNotifications";
import { Documents } from "src/interfaces/Documents";
import { COLORS } from "src/utils/styles";

interface UploadFilesDrawer {
  files: Documents[];
  visible?: boolean;
  onPressClose?: () => void;
}

interface FileIcon{
  status: string | undefined
}

const FileIcon = (props: FileIcon) => {
  const {status} = props
  return( <div className="flex-1 max-w-[10%] overflow-hidden flex items-center justify-center">
  {status === "Completed" ? (
    <div
      className="bg-green-500 flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <CheckIcon className="w-3 h-3 text-white fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : status === "Deleted" ? (
    <div
      className=" bg-red-600 flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <TrashIcon className="w-3 h-3 text-white fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : status === "Upload Failed" || status === "File content exceeds 50K characters" ? (
    <div
      className=" flex items-center justify-center rounded-full"
      style={{ width: "20px", height: "20px" }}
    >
      {/* Green background with set dimensions to create a perfect circle */}
      <CancelRounded className="w-6 h-6 text-red-600 fill-current" />{" "}
      {/* Icon is white */}
    </div>
  ) : (
    <Loader size={20} />
  )
  }
</div>)
}

function UploadFilesDrawer(props: UploadFilesDrawer) {
  const { files, onPressClose } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const client = useNotifications()?.client;
  const isMatchces = useMediaQuery("(max-width: 992px)");
  const [list, setList] = useState(files);
  const [initialized, setInitialized] = useState(false);
  const [processedCount, setProcessedCount] = useState(0)
  const useTheme = useMantineTheme();

  const closeList = useCallback(() => {
    // setList((prev) => []);
    close();
  }, [close]);

  const wipeList = useCallback(() => {
    close()
    setList((prev) => [])
    setProcessedCount(0)
  }, [])

  const listenForQueueFiles = useCallback(() => {
    if (!initialized) {
      client?.on("server-message", (data) => {
        if (data.message.data) {
          console.log("Notification receieved", data.message.data);
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.fileUpload) {
            setList((prevFiles) => {
              // Update the status of the relevant file
              const newFiles = prevFiles.map((file, index) => {
                if (file.filename === message.file_name) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if(message.status == "Completed" || message.status == "Upload Failed" || message.status == "File content exceeds 50K characters"){
                setProcessedCount((prev) => prev + 1)
              }

              // Check if all files are completed
              const allCompleted = newFiles.every(
                (file) =>
                  file.status === "Completed" || file.status === "Upload Failed" || file.status == "File content exceeds 50K characters"
              );

              // If all files are completed, filter them out
              if (allCompleted) {
                if (onPressClose) {
                  onPressClose();
                  setTimeout(wipeList, 2000)
                }
              }

              // Otherwise, return the updated list
              return newFiles;
            });
          }
          if (message.event_type === PubSubNotificationTypes.fileDeletion) {
            setList((prevFiles) => {
              // Update the status of the relevant file
              const newFiles = prevFiles.map((file) => {
                if (file.filename === message.file_name) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if(message.status == "Deleted"){
                setProcessedCount((prev) => prev + 1)
              }
              // Check if all files are completed
              const allCompleted = newFiles.every(
                (file) => file.status === "Deleted"
              );

              // If all files are completed, filter them out
              if (allCompleted) {
                if (onPressClose) {
                  console.log("Here is the call");
                  onPressClose();
                  setTimeout(wipeList, 2000)
                }
              }

              // Otherwise, return the updated list
              return newFiles;
            });
          }
        }
      });
    }
  }, [setList, client, initialized, onPressClose]);

  useEffect(() => {
    if (files.length > 0) {
      setList(files);
    }
  }, [files]);

  useEffect(() => {
    if (list?.length > 0) {
      open();
      listenForQueueFiles();
      setInitialized(true);
    }
  }, [list, client]);

  const getStatusColor = useCallback((status: string | undefined) => {
    switch (status) {
      case "Uploading":
        return colors.blue[600];
      case "Uploaded":
        return colors.blue[600];
      case "Chunking and Vectorising":
        return colors.blue[600];
      case "Completed":
        return colors.green[400]
      case "Queued For Google Drive Transfer":
        return colors.blue[600];
      case "Transferring From Google Drive":
        return colors.blue[600];
      case "Deleting":
        return colors.red[600]
      case "File content exceeds 50K characters": 
        return colors.red[600]
      case "Deleted":
        return colors.red[600]
      default:
        return colors.blue[600];
    }
  }, []);

  const getFileStatus = (status: string | undefined) => {
    if(status == "Chunking and Vectorising"){
      return "Processing"
    }else{
      return status
    }
  }

  return (
    <>
      <Drawer
        opened={opened}
        transitionProps={{
          transition: "rotate-left",
          duration: 150,
          timingFunction: "linear",
        }}
        withCloseButton = {false}
        onClose={closeList}
        title={list?.length > 1 ? "Processing Documents" : "Processing Document"}
        padding="lg"
        position="bottom"
        withOverlay={false}
        styles={(theme) => ({
          inner: {
            maxWidth: isMatchces ? "100%" : 350,
          },
          body: {
            marginTop: 10,
          },
          title: {
            fontStyle: "italic",
            color: theme.colorScheme == "dark" ? "white" : "black",
            fontWeight: 500
          },
          header: {
            backgroundColor: theme.colorScheme == "dark" ? theme.colors.dark[8] : theme.colors.blue[1],
            borderRadius: "16px 16px 0 0",
            position: "sticky",
            height: 10,
          },
        })}
      >
        <Text style={{fontSize: 14, color: colors.blueGrey[700]}}>{processedCount} of {list?.length} {list?.length > 1 ? "documents" : "document"} processed</Text>
        {list.map((file, index) => (
          <>
            <div key={index} className="flex w-full items-center py-2">
              {" "}
              {/* Adds a border as divider */}
              <div className="flex-1 max-w-[90%] overflow-hidden">
                <Text style={{ fontSize: 14 }}>{file.filename}</Text>
                <Text style={{paddingTop: 5,fontSize: 11, color: getStatusColor(file.status), fontWeight: 'bold'}}>{getFileStatus(file.status)}</Text>
              </div>
              <FileIcon status={file?.status} />
            </div>
            <Divider w={"100%"} />
          </>
        ))}
      </Drawer>
    </>
  );
}

export default UploadFilesDrawer;
