import { Modal, Button, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { COLORS } from 'src/utils/styles';


interface ConfirmationModal{
title?: string
feedback: string
confirmLabel?: string
onClose?: () => void
onConfirm?: () => void
}

export const confirmDeletionModal = (props: ConfirmationModal) => {
    const {title, feedback, onClose, onConfirm, confirmLabel} = props

  modals.openConfirmModal({
    title: title || 'Please confirm your action',
    centered: true,
    children: (
      <Text size="sm">
          {feedback}
      </Text>
    ),
    labels: { confirm: confirmLabel || "Confirm Delete", cancel: "Cancel" },
    confirmProps: {
        color: 'red',
        styles: { 
          root: {
            backgroundColor: 'red', // Override for 'Delete' button
            color: 'white',
            borderWidth: 0
          }
        }
      },
    onCancel: onClose,
    onConfirm: onConfirm,
  });

}