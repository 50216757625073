import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { store } from "../configureStore"
import { AdminAppSettings, AppSettings } from "src/interfaces/AppSettings"
import { User } from "src/interfaces"
import { getState } from "../types"
import { Dispatch } from "react"
import { authService } from "src/services/services/auth/auth.api"
import { ResponseKind } from "src/services/services/MainService"
import { tranformUser } from "./transformer"

const initialState: User = {
  email: "",
  id: "",
  plan: "",
  paid: false,
  trial_expired: false, 
  name: "",
  azureStorageToken: ""
}

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.email = action.payload.email
      state.id = action.payload.id
      state.paid= action.payload.paid
      state.plan = action.payload.plan
      state.trial_days = action.payload.trial_days
      state.trial_expired = action.payload.trial_expired
      state.role = action.payload.role
      state.name = action.payload.name
    },
    clearUser: (state) => {
      state.azureStorageToken = ''
      state.email = ''
      state.paid = false
      state.trial_days = 0
      state.plan = ''
      state.trial_expired = false
      state.id = ''
      state.role = 0
      state.name = ''
    },
    setAzureToken: (state, action: PayloadAction<string>) => {
      state.azureStorageToken = action.payload
    },
  },
})

const {setUser, setAzureToken, clearUser} = user.actions


export const updateUser = (user: User) => {
  const userData = tranformUser(user)
  return {
  type: setUser.type,
  payload: userData,
}}

export const resetUser = () => ({
  type: clearUser.type,
})


export const handleUserUpdate = () => {
  return async (dispatch: Dispatch<any>, state: getState) => {
    try{
      const response = await authService.getUserData()
      if(response.kind == ResponseKind.OK && response.data){
        // const user = tranformUser(response.data)
        dispatch(updateUser(response.data))
      }else{
        throw "Error"
      }

    }catch{
        // console.log("Could not update user to the redux");
    }
  }
}
export const updateAzureToken = (token: string) => ({
  type: setAzureToken.type,
  payload: token,
})


export default user.reducer
