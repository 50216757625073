import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Group,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { FacebookRounded } from "@mui/icons-material";
import { AppStatusCardFooter } from "./StatusCardFooter";
import Config from "src/config/config";
import useResponsive from "src/hooks/useResponsive";

const MAX_FILE_COUNT_FREE = ""

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: "0.25rem",
    fontSize: "0.75rem",
    height: rem(30),
    borderWidth: 0.5,
  },
}));

interface UserStatusCard {
  paid: boolean;
  handlePayment: () => void;
  trialDays?: number;
}

export const UserStatusCard = (props: UserStatusCard) => {
  const { paid, handlePayment, trialDays } = props;
  const { classes } = useStyles();
  const {isMobileMediumAndSmall} = useResponsive()

  return (
    <Card
      p={"sm"}
      mb={"md"}
      w={"100%"}
      withBorder
      className="bg-theme-color-1 rounded-lg"
    >
      <Button
        w={"100%"}
        className={classes.button}
        onClick={handlePayment}
        loaderPosition="center"
        styles={(theme) => ({
          root: {
            backgroundColor: "#FFFFFF",
            "&:not([data-disabled])": theme.fn.hover({
              backgroundColor: theme.fn.darken(theme.colors.grape[0], 0.05),
            }),
          },
          leftIcon: {
            color: "black",
          },
        })}
      >
        <Text style={{ color: "black" }} size={12}>
          {paid ? "Manage subscription" : "Upgrade Now"}
        </Text>
      </Button>

      {paid ? (
        <Center mt={5} mb={10}>
          <Box></Box>
        </Center>
      ) : (
        <Center mt={5} mb={5}>
          <Box>
            <Text align="center" style={{ color: "white" }} size={12}>
              {`You have ${trialDays} days of free trial remaining`}
            </Text>
            <Text align="center" style={{ color: "white" }} size={12}>
            {`Free trial limit: ${Config.FREE_PLAN_MSG_LIMIT} messages`}
            </Text>
          </Box>
        </Center>
      )}

      <AppStatusCardFooter />
    </Card>
  );
};
