import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Group,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { FacebookRounded } from "@mui/icons-material";
import { AppStatusCardFooter } from "./StatusCardFooter";

export const AdminStatusCard = () => {
  return (
    <Card
      p={"sm"}
      mb={"md"}
      w={"100%"}
      withBorder
      className="bg-theme-color-1 rounded-lg"
    >
      <div>
        <Center mt={5} mb={10}>
          <Box>
            <Text style={{ color: "white" }} fw={800} size={12}>
              Admin Portal
            </Text>
          </Box>
        </Center>
      </div>

      <AppStatusCardFooter />
    </Card>
  );
};
